import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArticleItemTitle from './title';
import ArticleItemDescription from './description';
import './_ArticleItem.scss';
import Card from '../../Card';
import FavorizeButton from '../../FavorizeButton';

export default class ArticleItem extends Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.isFavored !== nextProps.isFavored) {
            return true;
        }
        if (this.props.id !== nextProps.id) {
            return true;
        }

        return false;
    }

    render() {
        const { isFavored } = this.props;

        return (
            <Card>
                <div className="odt-ArticleItem" style={this.props.style}>
                    <div className="odt-ArticleItem__header">
                        <ArticleItemTitle {...this.props}/>
                        <FavorizeButton
                            userRole={this.props.userRole}
                            isFavored={isFavored}
                            id={this.props.id}
                            submit={this.props.setFavoritArticles}
                        />
                    </div>
                    <ArticleItemDescription
                        description={this.props.plain_text}
                        maxWordCount={this.props.maxWordCount}
                        maxCharCount={this.props.maxCharCount}
                    />
                </div>
            </Card>
        );
    }
}

ArticleItem.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    plain_text: PropTypes.string.isRequired,
    maxWordCount: PropTypes.number,
    maxCharCount: PropTypes.number
}
