import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import { FormButtons, FormHeader, FormField } from '../Form';

import './_ArticleChangelog.scss';
import Paragraph from '../Paragraph';
import LayoutContainer from '../LayoutContainer';
import ButtonGroup from '../ButtonGroup';
import Headline from '../Headline';

class ArticleChangelog extends Component {

    constructor(props){
        super(props);
        this.state = {
            isChanged: false,
            changes: []
        };
    }

    _toggle = (option) => {
        const { changes } = this.state;
        let index = changes.indexOf(option.value);
        if(index === -1){
            changes.push(option.value);
        } else {
            changes.splice(index, 1);
        }
        this.setState({
            isChanged: !!changes.length,
            changes: changes
        });
    };

    _onSubmit = (event) => {
        event.preventDefault();
        const {onSubmit} = this.props;
        if(onSubmit){
            onSubmit(this.state.changes);
        }
    };

    render(){
        const { changes, isChanged } = this.state;
        const { options, formTitle } = this.props;

        return (
            <div className="ArticleChangelog">
                <LayoutContainer small>
                    <FormButtons {...this.props} disabled={!isChanged} onSubmit={this._onSubmit}/>
                    {formTitle && <FormHeader><Headline size="subtitle3">{formTitle}</Headline></FormHeader>}
                </LayoutContainer>
                <div className="ArticleChangelog__content">
                    <ButtonGroup vertically>
                        {
                            options.map(option => {
                                const isSelected = changes.indexOf(option.value) !== -1;
                                return (
                                    <Button
                                        outline
                                        chip
                                        key={option.value}
                                        isSelected={isSelected}
                                        onClick={() => this._toggle(option)}
                                    >
                                        {option.text}
                                    </Button>
                                );
                            })
                        }
                    </ButtonGroup>
                    <div className="ArticleChangelog__message">
                        <FormField
                            name="title"
                            rows={3}
                            placeholder="Füge einen Kommentar hinzu..."
                            label="Kommentare oder Empfehlungen?*"
                            onChange={this._onFieldChange}
                        />
                        <Paragraph size="body2">* Optional</Paragraph>
                    </div>
                </div>
            </div>
        );
    }
}


ArticleChangelog.propTypes = {
    formTitle: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
        })
    ).isRequired
};

ArticleChangelog.defaultProps = {
    formTitle: 'Was wurde korrigiert?',
    options: [
        {value: 'TYPING', text: 'Tippfehler korrigiert'},
        {value: 'GRAMMAR', text: 'Grammatik korrigiert'},
        {value: 'LINKS', text: 'Links hinzugefügt'},
        {value: 'TAGS', text: 'Tags hinzugefügt'},
        {value: 'OTHER', text: 'Sonstiges'},
    ],
};


export default ArticleChangelog;