import Fuse from 'fuse.js';
import '../polyfills/includes';

/**
 *
 * @param {Array} articles
 * @param {String} query
 * @returns {Array} Filtered Article IDs
 */

export function filterArticlesByQuery(articles, query) {
    if (!articles) return false;

    const options = {
        sort: true,
        threshold: 0.1,
        location: 0,
        distance: (query.length > 2 ? 100 : 1),
        maxPatternLength: 32,
        minMatchCharLength: 0,
        keys: [
            "title"
        ],
    };

    const fuse = new Fuse(articles, options);
    const result = fuse.search(query).map(({id}) => id);

    return result;
}