import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import AppContext from '../../context/AppContext';
import SearchResults from '../../components/SearchResults';
import RandomArticles from '../../components/RandomArticles';
import { getTagsById } from '../../helpers/getTagsById';
import parseArticleRoute from '../../helpers/parseArticleRoute';

export default function SearchContainer({location, history, baseRoute}) {

    const {
        getArticlesById,
        randomArticles,
        filteredArticles,
        availableTags,
        selectedTags,
        setSelectedTags,
        locallySelectedTags,
        setLocallySelectedTags,
        } = useContext(AppContext);

    const { pathname, search } = location;

    // state
    const [shownArticles, setShownArticles] = useState(null);
    const [searchTags, setSearchTags] = useState(null);

    // set tags by location search parameters
    const setUrlParamsQueryString = useCallback((searchStr) => {
        const urlParams = queryString.parse(searchStr, {arrayFormat: 'bracket'});
        if (urlParams.tags && urlParams.tags.map) {
            const urlParamsTags = urlParams.tags.map(tag => parseInt(tag));
            setSelectedTags(urlParamsTags);
            setLocallySelectedTags(urlParamsTags);

            window.scrollTo(0,0);
            history.replace({
                ...location,
                search: ''
            });
        }
    }, [history, location, setSelectedTags, setLocallySelectedTags]);

    // remove tag
    const removeTag = useCallback((id) => {
        const updatedTags = [
            ...selectedTags.filter(selectedTag => selectedTag !== id)
        ];
        const updatedLocalTags = [
            ...locallySelectedTags.filter(localTag => localTag !== id)
        ];
        setSelectedTags(updatedTags);
        setLocallySelectedTags(updatedLocalTags);
    }, [selectedTags, setSelectedTags, locallySelectedTags, setLocallySelectedTags]);


    // location search
    useEffect(() => {
        if(search){
            setUrlParamsQueryString(search);
        }
    }, [search, setUrlParamsQueryString]);


    // update local articles
    useEffect(() => {
        window.scrollTo(0,0);
        if(filteredArticles){
            setShownArticles(getArticlesById(filteredArticles));
        } else if(randomArticles){
            setShownArticles(getArticlesById(randomArticles));
        } else {
            setShownArticles(null);
        }
    }, [filteredArticles, randomArticles, getArticlesById]);


    // update selected tags
    useEffect(() => {
        if(availableTags && selectedTags){
            setSearchTags(getTagsById(availableTags, selectedTags));
        } else {
            setSearchTags(null);
        }
    }, [availableTags, selectedTags]);


    const articleRoute = parseArticleRoute(pathname);
    const showSearchResults = !!filteredArticles;
    const showRandom = !showSearchResults;
    const showRandomButton = articleRoute === null;
    const hideList = articleRoute !== null;

    return (
        <div className="odt-SearchContainer" style={hideList ? {visibility:'hidden'} : null}>

            { showRandom &&
            <RandomArticles
                articles={shownArticles}
                showButton={showRandomButton}
            />
            }

            { showSearchResults &&
            <SearchResults
                baseRoute={baseRoute}
                articles={shownArticles}
                tags={searchTags}
                removeTag={removeTag}
            />
            }

        </div>
    );
}


SearchContainer.propTypes = {
    baseRoute: PropTypes.string
};

SearchContainer.defaultProps = {
    baseRoute: '/search'
};