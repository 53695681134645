import React from 'react';
import PropTypes from 'prop-types';

import GlossaryListItem from './item';

import Headline from '../Headline';
import Card from '../Card';

import './_GlossaryListSection.scss';

const baseClassName = 'GlossaryListSection';

export default function GlossaryListSection(props){

    const {id, title, articles, itemHeight, isActive} = props;

    const sectionHeight = window.innerWidth >= 768 ? `${Math.ceil(articles.length / 3) * itemHeight }px` : `${articles.length * itemHeight }px`;

    return (
        <div className={baseClassName} data-section-id={id} data-id={id}>
            <Card isClickable={false} big >
                <Headline className={`${baseClassName}-title`} size="h6">{title}</Headline>
                <div
                    className={`${baseClassName}-items`}
                    style={{height: sectionHeight}}
                >
                    {
                        articles.map(article => {
                            return isActive ? (
                                <GlossaryListItem
                                    {...article}
                                    key={article.id}
                                    maxWordCount={0}
                                />
                            ) : null;
                        })
                    }

                </div>
            </Card>
        </div>
    );
}

GlossaryListSection.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    articles: PropTypes.array.isRequired,
    isActive: PropTypes.bool,
    itemHeight: PropTypes.number
};

GlossaryListSection.defaultProps = {
    isActive: false,
    itemHeight: 53
};