/**
 * Returns baseRoute, action and articleId
 * if the pathname matches any article rules.
 * @param pathname
 * @returns {null|{baseRoute: string, action: string, articleId: string|null}}
 */
export default function parseArticleRoute(pathname){
    if(!pathname) return null;
    let matches = pathname.match(/(\/[^/]+)\/article\/(create)/);
    if(matches){
        return {
            baseRoute: matches[1],
            action: matches[2],
            articleId: null,
        }
    }
    matches = pathname.match(/^(\/[^/]+)\/article\/(view|update)\/([0-9]+)/);
    if(matches){
        return {
            baseRoute: matches[1],
            action: matches[2],
            articleId: matches[3],
        }
    }
    return null;
}