import React from 'react';
import { Link } from 'react-router-dom'
import toast from '../components/Toast';
import IconSvg from '../components/IconSvg';
import { addFavoriteArticle, removeFavoriteArticle, clearFavoriteArticles } from "../api/article/favorite";

/**
 *
 * @param {Number} articleId
 * @param {Object} state
 * @param {Function} setState
 * @param {Function} callback
 */
export function setFavoritArticles(articleId=null, state, setState, callback) {
    const { favoritArticles } = state;
    const isAlreadyFavored = favoritArticles && favoritArticles.filter(favArticle => favArticle === articleId).length > 0;
    const reloadButton = (
        window.navigator.onLine
            ?
            <button onClick={() => { window.location.reload(); window.sessionStorage.clear(); }}>
                <IconSvg name="reload" caption="Neu laden" />
            </button>
            :
            null
    );
    const errorMessage = (
        <div className="toast__columns">
            {
                navigator.onLine ?
                    'Etwas ist schief gelaufen. Bitte probieren Sie es erneut.'
                    : 'Bitte überprüfen Sie Ihre Internetverbindung.'
            }
            {reloadButton}
        </div>
    );

    return new Promise((resolve, reject) => {
        if (!articleId) {
            clearFavoriteArticles(state)
                .then(updatedFavoritArticles => {
                    return new Promise((resolve, reject) => {
                        if (!updatedFavoritArticles) {
                            return reject();
                        }
                        setState({ favoritArticles: updatedFavoritArticles.map(response => response.id) }, () => {
                            toast.success(
                                <div>
                                    <IconSvg name="checkCircle" />
                                    Alle Begriffe wurden aus "Meine Liste" entfernt.
                                </div>
                            );
                            resolve();
                        });
                    }
                    )
                }).catch(() => {
                    reject();
                    toast.error(errorMessage);
                })
                .finally(() => resolve())
        }
        else if (isAlreadyFavored) {
            removeFavoriteArticle(articleId, state)
                .then(updatedFavoritArticles => {
                    return new Promise((resolve, reject) => {
                        if (!updatedFavoritArticles) {
                            return reject();
                        }
                        setState({ favoritArticles: updatedFavoritArticles.map(response => response.id) }, () => {
                            toast.success(
                                <div className="toast__columns">
                                    Der Begriff wurde aus "Meine Liste" entfernt.
                                    <Link to="/favorites">Ansehen</Link>
                                </div>
                            );
                            resolve();
                        });
                    }
                    )
                }).catch(() => {
                    reject();
                    toast.error(errorMessage);
                })
                .finally(() => resolve())
        } else {
            addFavoriteArticle(articleId, state)
                .then(updatedFavoritArticles => {
                    return new Promise((resolve, reject) => {
                        if (!updatedFavoritArticles) {
                            return reject();
                        }
                        setState({ favoritArticles: updatedFavoritArticles.map(response => response.id) });

                        toast.success(
                            <div className="toast__columns">
                                Der Begriff wurde zu "Meine Liste" hinzugefügt.
                                <Link to="/favorites">Ansehen</Link>
                            </div>
                        );
                        resolve();
                    }
                    )
                }).catch(() => {
                    reject();
                    toast.error(errorMessage);
                })
                .finally(() => resolve())
        }
    })
}
