import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { WindowScroller, AutoSizer, CellMeasurer, List, CellMeasurerCache } from 'react-virtualized';
import { isArticleFavored } from '../../helpers/isArticleFavored';
import ArticleItem from '../ArticleList/ArticleItem';

import './_ArticleListMasonry.scss';

class ArticleListMasonry extends PureComponent {
    constructor() {
        super();

        this.state = {
            columnCount: 1,
        };

        this._cache = new CellMeasurerCache({
            defaultHeight: 75,
            fixedWidth: true
        });

        this._rowRenderer = this._rowRenderer.bind(this);
        this._recaltulateHeight = this._recaltulateHeight.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.articles) !== JSON.stringify(prevProps.articles)) {
            this._recaltulateHeight();
            this.virtualizedList.forceUpdateGrid();
        }
        if (JSON.stringify(this.props.favoritArticles) !== JSON.stringify(prevProps.favoritArticles)) {
            this.virtualizedList.forceUpdateGrid();
        }
    }

    _recaltulateHeight = rowIndex => {
        const cacheObject = this._cache;
        rowIndex ? cacheObject.clear( rowIndex ) : cacheObject.clearAll();
        this.virtualizedList && this.virtualizedList.recomputeRowHeights();
    }

    _rowRenderer({
        key,         // Unique key within array of rows
        isScrolling, // The List is currently being scrolled
        isVisible,   // This row is visible within the List (eg it is not an overscanned row)
        parent,
        index,       // Index of row within collection
        style        // Style object to be applied to row (to position it)
     }) {
        const { articles, favoritArticles, location } = this.props;
        const article = articles[index];
        const isFavored = isArticleFavored(article.id, favoritArticles);
        const baseRoute = '/' + location.pathname.replace(/^\//, '').split('/')[0];

        return (
            <div
                key={key}
                style={style}
                onClick={index => this._recaltulateHeight(index)}
            >
                <CellMeasurer
                    cache={this._cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    <div className={`odt-ArticleListMasonry__rowContent ${isVisible ? 'is-visible' : ''}`}>
                        <ArticleItem
                            userRole={this.props.userRole}
                            key={article.id}
                            id={article.id}
                            plain_text={article.plain_text}
                            title={article.title}
                            maxCharCount={90}
                            isFavored={isFavored}
                            baseRoute={baseRoute}
                            setFavoritArticles={this.props.setFavoritArticles}
                        />
                    </div>
                </CellMeasurer>
            </div>
          )
    }

    render() {
        const { articles, scrollElement } = this.props;

        return (
            <div className="odt-ArticleListMasonry">
                <WindowScroller scrollElement={scrollElement}>
                    {({ height, scrollTop }) => {
                        return height > 0 && (
                            <AutoSizer disableHeight>
                                {({ width }) => (
                                    <List
                                        autoHeight
                                        scrollTop={scrollTop}
                                        height={height}
                                        width={width}
                                        rowCount={articles.length}
                                        rowHeight={this._cache.rowHeight}
                                        rowRenderer={this._rowRenderer}
                                        ref={ref => this.virtualizedList = ref}
                                    />
                                )}
                            </AutoSizer>
                        );
                    }}
                </WindowScroller>
            </div>
        );
    }
}

export default withRouter(ArticleListMasonry);
