import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import './_Tag.scss';
import IconSvg from '../../IconSvg';

export default class Tag extends PureComponent {
    _clickTag = (event) => {
        event.preventDefault();
        const {toggle, click, id} = this.props;
        if(toggle){
            toggle(id);
        }
        if(click){
            click(id);
        }
    };

    _clickRemove = (event) => {
        event.preventDefault();
        const {remove, id} = this.props;
        if(remove){
            remove(id);
        }
    };

    render() {
        const {
            id,
            title,
            isLinked,
            isSelectable,
            isSelected,
            isRemovable,
            outline
        } = this.props;
        let classNames = ['odt-Tag'];

        if (isSelectable) {
            classNames.push('is-selectable');

            if (isSelected){
                classNames.push('is-selected');
            }
        }
        if (isLinked) classNames.push('has-link');
        if (outline) classNames.push('odt-Tag--outline');

        let button = '';

        if (isLinked) {
            button = (
                <Link
                    className={`odt-Tag__title`}
                    to={{
                        pathname: "/search",
                        search: `?tags[]=${id}`
                    }}
                >
                    {title}
                </Link>
            );
        } else {
            button = (
                <button
                    className={`odt-Tag__title ${!isSelectable ? 'is-disabled' : ''}`}
                    disabled={!isSelectable}
                    onClick={isSelectable ? this._clickTag : null}
                >
                    {title}
                </button>
            );
        }

        return (
            <li className={classNames.join(' ')} data-tag-id={id}>
                <div className="odt-Tag__inner">
                    {button}
                    {
                        isRemovable
                            &&
                            <div className="odt-Tag__removeWrapper">
                                <button
                                    className="odt-Tag__remove"
                                    onClick={this._clickRemove}
                                >
                                    <IconSvg name="cancelCircle" />
                                </button>
                            </div>
                    }
                </div>
            </li>
        );
    }
}

Tag.propTypes = {
    isLinked: PropTypes.bool,
    isSelectable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isSelected: PropTypes.bool,
    outline: PropTypes.bool,
    title: PropTypes.string.isRequired,
    toggle: PropTypes.func,
    remove: PropTypes.func
}

Tag.defaultProps = {
    isSelected: false
}

