import { createContext } from 'react';

const initialValue = {
    sections: [],
    activeSectionId: null,
    setActiveSectionId: () => {},
};

const GlossarContext = createContext(initialValue);

export default GlossarContext;
