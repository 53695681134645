import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';

import AppContext from '../../context/AppContext';
import Login from '../../components/Login';

export default function LoginContainer(props){

    const {
        isLoggedIn,
        login,
        setLogin,
        setUserRole,
        setAccessToken,
        } = useContext(AppContext);

    return (
        <div className="LoginContainer">
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={true}
            />
            <Login
                isLoggedIn={isLoggedIn}
                login={login}
                setIsLoggedIn={setLogin}
                setUserRole={setUserRole}
                setAccessToken={setAccessToken}
            />
        </div>
    );
}