import React, { useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';

import TagListSearchable from '../TagListSearchable';
import './_Filter.scss';

const Filter = (props) => {
    const { history } = props;
    const {
        availableTags,
        locallySelectedTags,
        setLocallySelectedTags,
        locallyFilteredArticles,
        setSelectedTags,
        toggleOffcanvas
        } = useContext(AppContext);

    const submitFiltersToApp = useCallback((tags)=> {
        setSelectedTags(tags);
        toggleOffcanvas();
        history.push('/search');
    }, [history, setSelectedTags, toggleOffcanvas]);

    const resultsCount = locallyFilteredArticles && locallyFilteredArticles.length > 0 ? locallyFilteredArticles.length : '';

    return (
        <div className="odt-Filter">
            <TagListSearchable
                outline
                height={520}
                tags={availableTags}
                locallySelectedTags={locallySelectedTags}
                buttonText={`${resultsCount} Begriffe anzeigen`}
                buttonDisabled={(resultsCount.length === 0)}
                searchTagsEnabled={false}
                setLocallySelectedTags={setLocallySelectedTags}
                submit={submitFiltersToApp}
            />
        </div>
    );
};

Filter.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Filter);