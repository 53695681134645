/**
 * Takes a response object and checks the status of it.
 * When user is unauthorized he gets logged out.
 *
 * @param {object} data response object from api
 */
export default function handleExpiredLogin(data) {
    const userRoleKey = 'userRole';
    const ssoUrl = `${process.env.REACT_APP_API_URL}/saml/login`;

    if (data.status === 401 || data.status === 403) {
        const userRole = window.sessionStorage.getItem(userRoleKey);
        console.log('handleExpiredLogin', data.status);
        logout();
        if (userRole !== '10') {
            window.location.href = ssoUrl;
        }
    }
}

/**
 * Removes login status and local storage items.
 */
export function logout() {
    window.sessionStorage.removeItem('accessToken');
    window.sessionStorage.removeItem('accessTokenExpiresAt');
    window.sessionStorage.removeItem('userRole');
    window.dispatchEvent(new Event("logout"));
}
