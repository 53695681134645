import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import Headline from '../../Headline';

export default class ArticleItemTitle extends PureComponent {
    render() {
        const { baseRoute } = this.props;
        return (
            <Headline as="h3" size="h6" className="odt-ArticleItem__title">
                <NavLink to={`${baseRoute}/article/view/${this.props.id}`} className="odt-ArticleItem__link">{this.props.title}</NavLink>
            </Headline>
        );
    }
}

ArticleItemTitle.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    baseRoute: PropTypes.string.isRequired
}