import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Paragraph from '../Paragraph';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Card from '../Card';

const DEFAULT_CONFIG = {
    abort: `Abbrechen`,
    confirm: `Bestätigen`
}

export default function Prompt({ children, open, config, onClickConfirm, onClickAbort }) {
    Modal.setAppElement('#odt-root');

    const CONFIG = {
        ...DEFAULT_CONFIG,
        ...config
    };

    const STYLES = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.34)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99999,
        },
        content: {
            position: 'relative',
            top: 'unset',
            right: 'unset',
            bottom: 'unset',
            left: 'unset',
            minWidth: 300,
            border: 0,
            background: 'none',
        }
    };

    return (
        <Modal isOpen={open} style={STYLES}>
            <Card isClickable={false}>
                <Paragraph
                    size="body2"
                    style={{ marginBottom: `2em` }}
                >
                    {children}
                </Paragraph>
                <ButtonGroup horizontal justify>
                    <Button
                        noPadding
                        text
                        onClick={onClickAbort}
                        value={CONFIG.abort}
                    />
                    <Button
                        noPadding
                        text
                        onClick={onClickConfirm}
                        value={CONFIG.confirm}
                    />
                </ButtonGroup>
            </Card>
        </Modal>
    );
}

Prompt.propTypes = {
    open: PropTypes.bool.isRequired,
    config: PropTypes.object,
    onClickConfirm: PropTypes.func.isRequired,
    onClickAbort: PropTypes.func.isRequired,
};