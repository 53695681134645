import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ICONS from './_icons';
import './_IconSvg.scss';

export default class IconSvg extends PureComponent {
    render() {
        const {
            name,
            size,
            color,
            caption,
            horizontal,
            className
        } = this.props;

        const styles = {
            svg: {
                display: 'inline-block',
                position: 'relative',
                width: size || '.8em',
                height: size || '.8em',
                verticalAlign: 'middle',
            },
            path: {
                fill: color || 'currentColor',
            },
        };

        const renderCaption = (
            <div
                className={`IconSvg__caption`}
                style={styles.caption}
            >
                {caption}
            </div>
        );
        const renderIcon = (
            <svg
                style={styles.svg}
                viewBox="0 0 256 256"
                className={className}
            >
                <path
                    style={styles.path}
                    d={ICONS[name]}
                />
            </svg>
        );

        if (caption && caption.length > 0) {
            return (
                <div className={`IconSvg has-caption ${horizontal ? 'IconSvg--horizontal' : ''} ${className ? className : ''}`}>
                    {renderIcon}
                    {caption && renderCaption}
                </div>
            );
        }

        return (
            <React.Fragment>
                {renderIcon}
            </React.Fragment>
        );
    }
}


IconSvg.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    caption: PropTypes.string
};