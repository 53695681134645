import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';
import ViewportContext from '../../context/ViewportContext';

import ArticleListMasonry from '../ArticleListMasonry';
import ArticleListMasonryDesktop from '../ArticleListMasonryDesktop';

export default function ArticleListView({articles, scrollElement, renderHeader, renderFooter}){
    const {
        userRole,
        favoritArticles,
        setFavoritArticles
        } = useContext(AppContext);
    const {
        isMobile
        } = useContext(ViewportContext);

    useEffect(() => {
        if(scrollElement){
            scrollElement.scrollTop = 0;
        }
    }, [articles, scrollElement]);

    return (
        <React.Fragment>
            { renderHeader }
            { isMobile &&
            <ArticleListMasonry
                scrollElement={scrollElement}
                userRole={userRole}
                articles={articles}
                favoritArticles={favoritArticles}
                setFavoritArticles={setFavoritArticles}
            />
            }
            { !isMobile &&
            <ArticleListMasonryDesktop
                scrollElement={scrollElement}
                userRole={userRole}
                articles={articles}
                favoritArticles={favoritArticles}
                setFavoritArticles={setFavoritArticles}
            />
            }
            { renderFooter }
        </React.Fragment>
    );
}

ArticleListView.propTypes = {
    articles: PropTypes.array,
    scrollElement: PropTypes.any,
    renderHeader: PropTypes.any,
    renderFooter: PropTypes.any,
};