import { createContext } from 'react';

const initialValue = {
    currentBackground: null,
    setRandomBackground: () => {},
};

const BackgroundContext = createContext(initialValue);

export default BackgroundContext;
