import React, { PureComponent } from 'react';
import './_Checkbox.scss';
import IconSvg from '../IconSvg';

export default class Checkbox extends PureComponent {
    handleChange = (e) => {
        const { onChange } = this.props;
        if (onChange) return onChange(e);
    }
    render() {
        const { name, value, label, checked } = this.props;

        return (
            <label
                className={`odt-Checkbox ${checked ? 'is-checked' : ''}`}
            >
                <div className="odt-Checkbox__inner">
                    <input
                        className="odt-Checkbox__native-control"
                        type="checkbox"
                        checked={checked}
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                    />

                    <div className="odt-Checkbox__background">
                        <IconSvg name="check" className="odt-Checkbox__icon" />
                    </div>

                    <div className="odt-Checkbox__label">
                        {label}
                    </div>
                </div>
            </label>
        );
    }
}