import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../IconSvg';
import './_SearchField.scss';

export default function SearchField({
    isSelected,
    onFocus,
    onBlur,
    onChange,
    hasIcon,
    light,
    name,
    value,
    placeholder})
{

    const inputRef = useRef();

    const handleChange = useCallback(() => {
        onChange(inputRef.current.value);
    }, [onChange]);

    const handleReset = useCallback(() => {
        onChange('');
        inputRef.current.select();
    }, [onChange]);

    useEffect(() => {
        if(isSelected){
            inputRef.current.select();
        }
    }, [isSelected]);

    return (
        <div className={`odt-SearchField ${light ? 'odt-SearchField--light' : ''}`}>
            <div className="odt-SearchField">
                { hasIcon &&
                <div className="odt-SearchField__icon">
                    <IconSvg name="search" size={13} />
                </div>
                }

                <input
                    className={`odt-SearchField__input ${hasIcon ? 'has-icon' : ''}`}
                    type="search"
                    autoComplete="off"
                    ref={inputRef}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                { value && value.length > 0 &&
                <button className="odt-SearchField__reset" onClick={handleReset}>
                    <IconSvg name="cancel" />
                </button>
                }

            </div>
        </div>
    );
}

SearchField.propTypes = {
    hasIcon: PropTypes.bool,
    light: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

SearchField.defaultProps = {
    hasIcon: true,
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
};