import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TagList from '../TagList';
import SearchResultsCount from '../SearchResultsCount';
import SearchField from '../SearchField';
import './_TagListSearchableLight.scss';

export default class TagListSearchableLight extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchFocused: false,
            searchSelected: false,
            searchQuery: ''
        }
        this.setSearchQuery = this.setSearchQuery.bind(this);
        this.getSearchResult = this.getSearchResult.bind(this);
        this._click = this._click.bind(this);
        this._onFocus = this._onFocus.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedTags.toString() !== prevProps.selectedTags.toString()
            && this.state.searchQuery.length > 0
        ) {
            this.setState({ searchSelected: true });
        } else {
            this.setState({ searchSelected: false });
        }
    }

    setSearchQuery(query) {
        this.setState({ searchQuery: query }, () => {
           this.props.onChange(query);
        });
    }

    getSearchResult() {
        const { tags } = this.props;
        const { searchQuery } = this.state;
        const trimmedSearchQuery = searchQuery.trim().toLowerCase();

        return tags.filter(tag => tag.title.toLowerCase().match(trimmedSearchQuery))
    }

    _onFocus() {
        this.setState({ searchFocused: true });
    }

    _click(tagId) {
        if (this.props.clickTag) {
            this.props.clickTag(tagId);
        }
    }

    render() {
        const {
            tags,
            selectedTags,
            inputPlaceholder,
            hasSearchIcon,
        } = this.props;
        const { searchQuery } = this.state;
        const tagsToDisplay = searchQuery.length > 0 ? this.getSearchResult() : tags;

        return (
            <div className="odt-TagListSearchableLight" ref={el => this.refSearchList = el}>

                <SearchField
                    hasIcon={hasSearchIcon}
                    isSelected={this.state.searchSelected}
                    placeholder={inputPlaceholder}
                    value={searchQuery}
                    onChange={this.setSearchQuery}
                    onFocus={this._onFocus}
                />

                {
                    (searchQuery.length > 0)
                        &&
                        <div className="odt-TagListSearchableLight__results">
                            <SearchResultsCount
                                resultsCount={tagsToDisplay.length}
                            />
                            <TagList
                                flatList={this.props.flatList}
                                outline={this.props.outline}
                                hasPadding={false}
                                tags={tagsToDisplay}
                                selectedTags={selectedTags}
                                click={this._click}
                            />
                        </div>
                }

            </div>
        );
    }
}

TagListSearchableLight.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    })).isRequired,
    selectedTags: PropTypes.array,
    setLocallySelectedTags: PropTypes.func,
    inputPlaceholder: PropTypes.string,
    handleLocalChanges: PropTypes.func,
    submit: PropTypes.func
}

TagListSearchableLight.defaultProps = {
    selectedTags: [],
    locallySelectedTags: [],
    inputPlaceholder: 'Kategorien durchsuchen'
}