import { post } from "../request";
import handleExpiredLogin from "../handleExpiredLogin";

export async function submitArticleFeedback(articleId, data, state, setState){
    const route = `/article/feedback/${articleId}`;
    return post(state, route, data).then(response => {
        const { feedback } = response;
        handleExpiredLogin(response);
        if (!feedback){
            return;
        }
        console.log('response', response)
        return response;
    });
}