import React, { PureComponent } from 'react';
import FeedbackStep from './FeedbackStep';
import { FormField } from '../Form';
import './_FeedbackSteps.scss';
import Headline from '../Headline';
import Checkbox from '../Form/checkbox';

export default class FeedbackSteps extends PureComponent {
    constructor() {
        super();
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
    }

    handleOptionChange(event) {
        const { selectedOptions, setSelectedOption } = this.props;
        const clickedOption = event.target.value;
        const isAlreadySelected = selectedOptions.filter(selectedOption => selectedOption === clickedOption).length > 0;
        let updatedOptions = [];

        if (isAlreadySelected) {
            updatedOptions = [
                ...selectedOptions.filter(selectedOption => selectedOption !== clickedOption)
            ];
        } else {
            updatedOptions = [
                ...selectedOptions,
                clickedOption
            ];
        }

        setSelectedOption(updatedOptions)
    }

    handleNoteChange(event) {
        const { setNote } = this.props;
        setNote(event.target.value)
    }

    render() {
        const {
            wasHelpful,
            isFinished,
            step,
            selectedOptions,
            note
        } = this.props;
        const OPTIONS = [
            {value: 'INCOMPREHENSIBLE', text: 'Erklärung unverständlich'},
            {value: 'TOO_SHORT', text: 'Erklärung zu kurz'},
            {value: 'NOT_INFORMATIVE', text: 'Erklärung ist falsch'},
            {value: 'OTHER', text: 'Sonstiges'},
        ]

        if (wasHelpful !== false || isFinished) return null;

        return (
            <div className="odt-FeedbackSteps">

                <FeedbackStep
                    step={step}
                    showOnStep={0}
                >
                    <Headline size="subtitle3">Warum war die Erklärung nicht hilfreich?</Headline>
                    {
                        OPTIONS.map(checkbox => {
                            return (
                                <Checkbox
                                    key={checkbox.value}
                                    type="checkbox"
                                    name="feedback-options"
                                    value={checkbox.value}
                                    label={checkbox.text}
                                    checked={selectedOptions.some(el => el === checkbox.value)}
                                    onChange={this.handleOptionChange}
                                />
                            )
                        })
                    }
                    <FormField
                        name="feedback-message"
                        rows={4}
                        placeholder="Kommentar (Optional)"
                        value={note}
                        onChange={this.handleNoteChange}
                    />
                </FeedbackStep>

            </div>
        );
    }
}