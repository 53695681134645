import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import IconSvg from '../IconSvg';
import './_item.scss';

export default class FavoritesListItem extends Component {
    _onClick = () => {
        const { id, remove } = this.props;

        if (!remove) return;

        remove('single', id)
    };

    render() {

        const { baseRoute, isLoading } = this.props;

        return (
            <div className={`odt-FavoritesListItem ${isLoading ? 'is-loading' : ''}`}>
                <NavLink to={`${baseRoute}/article/view/${this.props.id}`} className="odt-FavoritesListItem__link">{this.props.title}</NavLink>
                <button
                    disabled={isLoading ? true : false}
                    className="odt-FavoritesListItem__remove"
                    onClick={this._onClick}
                >
                    <IconSvg name="cancel" />
                </button>
            </div>
        );
    }
}