import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconSvg from '../IconSvg';

import './_FavorizeButton.scss';

export default class FavorizeButton extends PureComponent {
    constructor() {
        super();
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        this._setLoading();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isFavored } = this.props;
        if (isFavored !== prevProps.isFavored)  {
            this._setLoading();
        }
    }

    _setLoading = () => {
        const { isFavored } = this.props;

        if (isFavored === null) {
            this.setState({ isLoading: true })
        } else {
            this.setState({ isLoading: false })
        }
    }

    _onClick = () => {
        const { id, submit } = this.props;

        this.setState({ isLoading: true })

        submit(id)
            .then(() => this.setState({ isLoading: false }))
    }

    render() {
        const { userRole, className, isFavored, size } = this.props;
        const { isLoading } = this.state;

        const classNames = [
            'odt-FavorizeButton',
            isLoading ? 'is-loading' : '',
            isFavored ? 'is-favored' : '',
            className
        ];

        if (userRole && userRole === "10") return null;

        return (
            <button
                className={classNames.join(' ')}
                onClick={this._onClick}
            >
                <IconSvg name={isFavored ? 'heartFilled' : 'heartOutline'} size={size} />
            </button>
        );
    }
}

FavorizeButton.propTypes = {
    isFavored: PropTypes.bool,
    id: PropTypes.number.isRequired,
    submit: PropTypes.func.isRequired
}

FavorizeButton.defaultProps = {
    isFavored: null
}