import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import GlossarContext from '../../context/GlossarContext';
import parseArticleRoute from '../../helpers/parseArticleRoute';

import Glossary from '../../components/Glossary';
import LoadingIndicator from '../../components/LoadingIndicator';

export default function GlossarContainer({location}){

    const { pathname } = location;
    const {
        sections,
        activeSectionId,
        setActiveSectionId,
        } = useContext(GlossarContext);

    const articleRoute = parseArticleRoute(pathname);
    const isReady = sections && sections.length > 0;
    const hideList = articleRoute !== null;

    return (
        <div className="odt-GlossarContainer" style={hideList ? {visibility:'hidden'} : null}>
            <Helmet>
                <title>Glossar</title>
            </Helmet>

            { isReady &&
            <Glossary
                sections={sections}
                locked={hideList}
                activeSectionId={activeSectionId}
                onFocusSection={setActiveSectionId}
            />
            }

            { !isReady &&
            <LoadingIndicator/>
            }
        </div>
    );
}