import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconSvg from '../IconSvg';
import './_Button.scss';

class Button extends Component {

    render() {
        const {
            isLoading,
            isSelected,
            hasFailed,
            disabled,
            type,
            text,
            link,
            light,
            className,
            primary,
            secondary,
            outline,
            chip,
            floating,
            noPadding,
            value,
            icon,
            iconSize,
            to,
            onClick,
            children
        } = this.props;

        const compName = 'odt-Button';
        const classNames = [
            compName,
            className ? className : '',
            isSelected ? 'is-selected' : '',
            isLoading ? 'is-loading' : '',
            disabled ? 'is-disabled' : '',
            hasFailed ? 'has-failed' : '',
            icon ? 'has-icon' : '',
            primary ? `${compName}--primary` : '',
            secondary ? `${compName}--secondary` : '',
            floating ? `${compName}--floating` : '',
            noPadding ? `${compName}--noPadding` : '',
            text ? `${compName}--text` : '',
            link ? `${compName}--link` : '',
            outline ? `${compName}--outline` : '',
            chip ? `${compName}--chip` : '',
            light ? `${compName}--light` : '',
        ];

        const customText = (value) ? value : children;
        const renderIcon = icon ? <span className={`${compName}__icon`} style={iconSize && {fontSize: iconSize, marginRight: '.4em'}}><IconSvg name={icon} /></span> : '';

        if (type === 'link') {
            return (
                <Link
                    to={to}
                    className={classNames.join(' ')}
                    onClick={onClick}
                >
                    <div className={`${compName}__inner`}>
                        {renderIcon}
                        {customText}
                    </div>
                </Link>
            );
        }

        return (
            <button
                type={type}
                disabled={disabled || isLoading}
                className={classNames.join(' ').trim()}
                onClick={onClick}
            >
                <div className={`${compName}__inner`}>
                    {renderIcon}
                    {isLoading ? 'Lädt...' : customText}
                </div>
            </button>
        );
    }
}


Button.propTypes = {
    type: PropTypes.string,
    to: PropTypes.string,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

Button.defaultProps = {
    type: 'button',
    to: '',
    isSelected: false,
    disabled: false
};

export default Button;