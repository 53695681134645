import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './_LayoutContainer.scss';


export default class LayoutContainer extends PureComponent {

    render() {
        const { hasPadding, extraSmall, small, medium, large } = this.props;
        const baseName = "odt-LayoutContainer";
        const classNames = ["odt-LayoutContainer"];

        if (hasPadding) classNames.push(`has-padding`);
        if (extraSmall) classNames.push(`${baseName}--extraSmall`);
        if (small) classNames.push(`${baseName}--small`);
        if (medium) classNames.push(`${baseName}--medium`);
        if (large) classNames.push(`${baseName}--large`);

        return (
            <div className={classNames.join(" ")}>
                {this.props.children}
            </div>
        );
    }
}

LayoutContainer.propTypes = {
    hasPadding: PropTypes.bool,
    extraSmall: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
}

LayoutContainer.defaultProps = {
    hasPadding: true,
}