import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../../Paragraph';

export default class ArticleItemDescription extends PureComponent {
    render() {
        const { description, maxWordCount } = this.props;
        const words = description.trim().split(' ');
        const wordCount = words.length;
        const desc = (wordCount <= description) ? description.trim() : words.splice(0, maxWordCount).join(' ').trim();

        return (
            <Paragraph size="body2" className="odt-ArticleItem__desc">
                {
                    wordCount > maxWordCount
                        ?
                        `${desc}...`
                        :
                        desc
                }
            </Paragraph>
        );
    }
}

ArticleItemDescription.propTypes = {
    description: PropTypes.string.isRequired,
    maxWordCount: PropTypes.number,
}

ArticleItemDescription.defaultProps = {
    maxWordCount: 12,
}