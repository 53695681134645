import { createContext } from 'react';

const initialValue = {
    isMobile: true,
    randomSeed: 0,
    setRandomSeed: () => {},
};

const ViewportContext = createContext(initialValue);

export default ViewportContext;
