import handleExpiredLogin from "./handleExpiredLogin";

const API_VERSION = process.env.REACT_APP_API_VERSION;
const validMethods = ['GET','POST'];

/**
 *
 * @param route
 * @param data
 * @param state
 * @return {Q.Promise<any> | void | Q.Promise<T | never> | PromiseLike<T | never>}
 */
export function get(state, route, data){
    const request = createRequest(state, 'GET', data);
    return sendRequest(state, route, request).then(data => {
        if(data.response){
            return data.response;
        }
        return data;
    });
}

/**
 *
 * @param route
 * @param data
 * @param state
 * @return {Q.Promise<any> | void | Q.Promise<T | never> | PromiseLike<T | never>}
 */
export function post(state, route, data){
    const request = createRequest(state, 'POST', data);
    return sendRequest(state, route, request).then(data => {
        if(data.response){
            return data.response;
        }
        return data;
    });
}

function sendRequest(state, route, request){
    const { apiBaseUrl } = state;
    const url = `${apiBaseUrl}${route}?v=${API_VERSION}`;
    //console.log('sendRequest', url, request);
    return fetch(url, request)
        .then(response => response.json())
        .then(jsonData => {
            //console.log('sendRequest response', jsonData);
            handleExpiredLogin(jsonData);
            return jsonData;
        })
        .catch(err => {
            //console.log('sendRequest error', err);
            return err;
        })
}

function createRequest(state, method = 'GET', data){
    const { accessToken, userRole } = state;
    const tokenType = (userRole && userRole === '10') ? 'Bearer' : 'SamlBearer';
    method = method.toUpperCase();
    if(validMethods.indexOf(method) === -1){
        method = 'GET';
    }
    let request = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${tokenType} ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Headers': 'authorization,content-type'
        }
    };
    if(data){
        request.body = JSON.stringify(data);
    }
    return request;
}
