import { useState, useEffect } from 'react';

const useNetworkConnection = () => {

    const [hasNetworkConnection, setHasNetworkConnection] = useState();

    useEffect(() => {

        function handleConnectionChange(){
            const onLine = window.navigator.onLine;
            setHasNetworkConnection(onLine !== null ? !!onLine : null);
        }

        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);
        handleConnectionChange();

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        }
    }, []);

    return hasNetworkConnection;
};

export default useNetworkConnection;