export default function checkUrlParamsFor (param) {
    if (
        window.location.href.indexOf(`?${param}`) !== -1
        || window.location.href.indexOf(`&${param}`) !== -1
    ) {
        return true;
    }
    else {
        return false;
    }
}