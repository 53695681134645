import { post } from "../request";

/**
 *
 * @param articleId
 * @param state
 * @return {Q.Promise<any>}
 */
export function addFavoriteArticle(articleId, state){
    const route = `/favorite/add-article/${articleId}`;
    return post(state, route).then(response => {
        const { favoriteArticles } = response;
        if(!favoriteArticles){
            return;
        }
        return favoriteArticles;
    });
}

/**
 *
 * @param {number} articleId
 * @param {object} state
 */
export function removeFavoriteArticle(articleId, state){
    const route = `/favorite/remove-article/${articleId}`;
    return post(state, route).then(response => {
        const { favoriteArticles } = response;
        if(!favoriteArticles){
            return;
        }
        return favoriteArticles;
    });
}

/**
 *
 * @param {object} state
 */
export function clearFavoriteArticles(state){
    const route = `/favorite/clear-articles`;
    return post(state, route).then(response => {
        const { favoriteArticles } = response;
        if(!favoriteArticles){
            return;
        }
        return favoriteArticles;
    });
}