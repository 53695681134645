import React, { useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

import AppContext from '../../context/AppContext';

import FavoritesList from '../../components/FavoritesList';
import LayoutContainer from '../../components/LayoutContainer';
import Headline from '../../components/Headline';
import Button from '../../components/Button';
import Paragraph from '../../components/Paragraph';
import LoadingIndicator from '../../components/LoadingIndicator';
import Background from '../../components/Background';
import background from '../../../assets/images/dm-woerterbuch-background-favorites.svg';
import Prompt from '../../components/Prompt';


export default function FavoritesContainer(props){
    const { routeProps, baseRoute } = props;
    const { favoritArticles, setFavoritArticles, getArticlesById } = useContext(AppContext);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [removeType, setRemoveType] = useState('');
    const [itemId, setItemId] = useState('');
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        Modal.setAppElement('#odt-root');
    }, []);

    const openModal = useCallback(() => {
        setModalIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
        setRemoveType('');
        setItemId('');
    }, []);

    const remove = useCallback((type, id) => {
        setRemoveType(type);
        setItemId(id);
        openModal();
    }, [openModal]);

    const removeSingle = useCallback(() => {
        setLoading([...loading, itemId]);
        setFavoritArticles(itemId)
            .then(() => {
                setLoading(loading.filter(prev => prev !== itemId));
            });
        closeModal();
    }, [itemId, setFavoritArticles, closeModal, loading]);

    const removeAll = useCallback(() => {
        const loadingQueue = [...loading, ...favoritArticles];
        setLoading(loadingQueue);
        setFavoritArticles()
            .then(() => {
                setLoading([]);
            });
        closeModal();
    }, [favoritArticles, setFavoritArticles, closeModal, loading]);


    const renderContent = routeProps.match.path === baseRoute
        && routeProps.match.isExact === true;

    const articles = getArticlesById(favoritArticles);
    const modalRemove = (removeType === 'all') ? removeAll : (removeType === 'single') ? removeSingle : () => {};
    const modalMessage = removeType === 'all' ? 'Möchten Sie wirklich alle Begriffe entfernen?' : 'Möchten Sie den Begriff wirklich entfernen?';

    return (
        <div className="odt-FavoritesContainer" style={{position: 'relative'}}>
            <Helmet>
                <title>Meine Liste</title>
            </Helmet>

            { renderContent &&

            <React.Fragment>
                <Prompt
                    open={modalIsOpen}
                    config={{ confirm: `Entfernen` }}
                    onClickAbort={closeModal}
                    onClickConfirm={modalRemove}
                >
                    {modalMessage}
                </Prompt>

                <LayoutContainer extraSmall>

                    <div className="odt-FavoritesContainer__header" style={{ paddingTop: 24 }}>
                        <Headline as="h1" size="h5">
                            Meine Liste
                            ({favoritArticles ? favoritArticles.length : 0})
                        </Headline>
                    </div>

                    {
                        favoritArticles
                        && favoritArticles.length > 0
                            ?
                            <React.Fragment>
                                <Button
                                    text
                                    link
                                    noPadding
                                    value="Alle Begriffe entfernen"
                                    onClick={() => remove('all')}
                                />
                                <FavoritesList
                                    baseRoute={baseRoute}
                                    articles={articles}
                                    loading={loading}
                                    remove={remove}
                                />
                            </React.Fragment>
                            :
                            favoritArticles
                            && favoritArticles.length === 0
                                ?
                                <Paragraph size="body1">Keine Begriffe vorhanden</Paragraph>
                                :
                                <LoadingIndicator />

                    }

                </LayoutContainer>
                <Background showImage image={background} style={{ backgroundPosition: 'bottom' }} />
            </React.Fragment>
            }
        </div>
    );
}

FavoritesContainer.propTypes = {
    routeProps: PropTypes.shape({
        location: PropTypes.object,
        match: PropTypes.any,
    }),
    baseRoute: PropTypes.string,
};

FavoritesContainer.defaultProps = {
    baseRoute: '/favorites',
};