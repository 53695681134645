import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Headline from '../Headline';

let incrementId = 0;

class FormField extends PureComponent {
    render(){
        const {
            name,
            required,
            label,
            placeholder,
            value,
            defaultValue,
            onChange,
            onBlur,
            onFocus,
            rows,
            error
        } = this.props;

        const id = `FormField-${++incrementId}`;
        const inputLabel = label ? <label className="Form-label" htmlFor={id}><Headline size="subtitle3">{label}</Headline></label> : null;
        const type = this.props.type.toLowerCase();

        let input = null;
        let inputProps = { id, name, required, placeholder, value, onChange, onBlur, onFocus };
        inputProps.className = 'Form-control';

        switch(type){
            case 'component':
                input = this.props.children;
                break;
            case 'text':
            default:
                if(rows > 1){
                    input = <textarea ref={el => this.textAreaRef = el} {...inputProps} rows={rows} value={value} defaultValue={defaultValue} />;
                } else {
                    input = <input {...inputProps} autoComplete="off" type={type}/>;
                }
                break;
        }

        return (
            <div className={`Form-field${error ? ' error' : ''}`}>
                {inputLabel}
                {input}
            </div>
        );
    }
}


FormField.propTypes = {
    type: PropTypes.string,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    error: PropTypes.bool
};

FormField.defaultProps = {
    type: 'text',
    rows: 1,
    placeholder: '',
    error: false
};

export default FormField;