import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import getElementType from '../../helpers/getElementType';
import './_Headline.scss';

export default class Headline extends PureComponent {
    render() {
        const {
            size,
            hasMarginTop,
            className,
            children
        } = this.props;

        const ElementType = getElementType(Headline, this.props)
        const compName = 'odt-Headline';
        const classNames = [
            compName,
            `${compName}--${size}`,
            `${hasMarginTop ? 'has-marginTop' : ''}`,
            className
        ];

        return (
            <ElementType className={classNames.join(' ')}>
                {children}
            </ElementType>
        );
    }
}

Headline.propTypes = {
    /** An element type to render as string. */
    as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span']),

    /** A string added to the classNames */
    size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'subtitle3']),
}

Headline.defaultProps = {
    margin: false
}