
export const EasingFunctions = {
    linear: t => { return t },
    easeInQuad: t => { return t*t },
    easeOutQuad: t => { return t*(2-t) },
    easeInOutQuad: t => { return t<.5 ? 2*t*t : -1+(4-2*t)*t },
    easeInCubic: t => { return t*t*t },
    easeOutCubic: t => { return (--t)*t*t+1 },
    easeInOutCubic: t => { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 },
    easeInQuart: t => { return t*t*t*t },
    easeOutQuart: t => { return 1-(--t)*t*t*t },
    easeInOutQuart: t => { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t },
    easeInQuint: t => { return t*t*t*t*t },
    easeOutQuint: t => { return 1+(--t)*t*t*t*t },
    easeInOutQuint: t => { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t }
};


const animate = (elem, attr, from, to, time, unit, isProp, easing) => {
    if (!elem) {
        return;
    }

    isProp = typeof isProp === 'undefined' ? true : !!isProp;
    from = typeof from === 'undefined' ? parseFloat(isProp ? elem[attr] : elem.style[attr]) : parseFloat(from);
    to = parseFloat(to);

    if(isNaN(from) || isNaN(to)) {
        return;
    }

    let isFinished = false,
        isCanceled = false,
        fnEase = EasingFunctions.linear,
        tStart = (new Date()).getTime();

    if(easing){
        if(typeof easing === 'string' && typeof EasingFunctions[easing] === 'function'){
            fnEase = EasingFunctions[easing];
        } else if(typeof easing === 'function'){
            fnEase = easing;
        }
    }

    update();

    function update(){
        if(isCanceled || isFinished) return;
        var dt = (new Date().getTime()) - tStart,
            f = Math.min(1, dt / time),
            val = [from + fnEase(f) * (to - from), unit].join('');
        if (isProp) {
            elem[attr] = val;
        } else {
            elem.style[attr] = val;
        }
        if (f < 1) {
            window.requestAnimationFrame(update);
        } else {
            isFinished = true;
        }
    }

    return {
        cancel: () => {
            isCanceled = true;
        },
        isFinished: () => {
            return isFinished;
        }
    };
};

export default animate;