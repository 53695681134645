import { useContext, useEffect } from 'react';
import useForceUpdate from 'use-force-update';
import { __RouterContext } from 'react-router';

const useReactRouter = () => {
    const forceUpdate = useForceUpdate();
    const routerContext = useContext(__RouterContext);

    useEffect(() => {
        return routerContext.history.listen(forceUpdate);
    }, [routerContext, forceUpdate]);

    return routerContext;
};

export default useReactRouter;