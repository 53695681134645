import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './_ButtonGroup.scss';

export default class ButtonGroup extends Component {
    render() {
        const {
            horizontal,
            justify,
            center,
            vertically
        } = this.props;

        const compName = 'odt-ButtonGroup';
        const classNames = [
            compName,
            horizontal && `${compName}--horizontal`,
            justify && `${compName}--justify`,
            center && `${compName}--center`,
            vertically && `${compName}--vertically`
        ];

        return (
            <div className={classNames.join(' ')}>
                {this.props.children}
            </div>
        );
    }
}

ButtonGroup.propTypes = {
    horizontal: PropTypes.bool,
    justify: PropTypes.bool,
    vertically: PropTypes.bool
}

ButtonGroup.defaultProps = {
    horizontal: true
}