import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TagList from '../TagList';
import Headline from '../Headline';

class ArticleTags extends PureComponent {
    render() {
        const { article, availableTags } = this.props;
        const articleTags = availableTags && availableTags.filter(tag => article.tags.indexOf(tag.id) !== -1);

        if (!article.tags || article.tags.length === 0) return null;

        return (
            <div className="ArticleDetails-tags">
                <Headline size="subtitle3">Kategorien</Headline>
                <TagList
                    hasPadding={false}
                    isLinked
                    isSelectable={false}
                    isRemovable={false}
                    tags={articleTags}
                />
            </div>
        );
    }
}

ArticleTags.propTypes = {
    article: PropTypes.object.isRequired
}

export default ArticleTags;