import React, { PureComponent } from 'react';

import './_Box.scss';

class Box extends PureComponent {

    render() {
        const { name, onClick, children } = this.props;

        let classNames = ['Box'];
        if(name){
            classNames.push(name);
        }

        return (
            <div className={classNames.join(' ')} onClick={onClick}>{children}</div>
        );
    }
}


export default Box;