import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
    WindowScroller,
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    Masonry
  } from 'react-virtualized';
import { isArticleFavored } from '../../helpers/isArticleFavored';
import ArticleItem from '../ArticleList/ArticleItem';
import './_ArticleListMasonryDesktop.scss';

class ArticleListMasonryDesktop extends PureComponent {
    constructor() {
        super();

        this._columnCount = 3;

        this.state = {
            columnWidth: 320,
            gutterSize: 16,
        };

        this.cache = new CellMeasurerCache({
            defaultHeight: 80,
            defaultWidth: this._columnWidth,
            fixedWidth: true
        })

        this._resetCellPositioner = this._resetCellPositioner.bind(this);
        this._calculateColumnCount = this._calculateColumnCount.bind(this);
        this._initCellPositioner = this._initCellPositioner.bind(this);
        this._onResize = this._onResize.bind(this);
        this.cellRenderer = this.cellRenderer.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.articles) !== JSON.stringify(prevProps.articles)
            || JSON.stringify(this.props.favoritArticles) !== JSON.stringify(prevProps.favoritArticles)
        ) {
            this.cache.clearAll();
            this._resetCellPositioner();
            this.masonryRef.clearCellPositions();
        }
    }

    _resetCellPositioner() {
        const {columnWidth, gutterSize} = this.state;

        this.cellPositioner.reset({
            columnCount: this._columnCount,
            columnWidth,
            spacer: gutterSize,
        });
    }

    _calculateColumnCount() {
        const {columnWidth, gutterSize} = this.state;

        this._columnCount = Math.floor(this._width / (columnWidth + gutterSize));
    }

    _initCellPositioner() {
        const { columnWidth, gutterSize } = this.state;

        this.cellPositioner = createMasonryCellPositioner({
            cellMeasurerCache: this.cache,
            columnCount: this._columnCount,
            columnWidth,
            spacer: gutterSize
        });
    }

    _onResize({width}) {
        this._width = width;
        this._calculateColumnCount();
        this._resetCellPositioner();
        this.masonryRef.recomputeCellPositions();
    }

    cellRenderer({
        index,       // Index of item within the collection
        isScrolling, // The Grid is currently being scrolled
        key,         // Unique key within array of cells
        parent,      // Reference to the parent Grid (instance)
        style        // Style object to be applied to cell (to position it);
        // This must be passed through to the rendered cell element.
    }) {
        const {articles, favoritArticles, location, setFavoritArticles} = this.props;
        const article = articles[index];

        if (!article) return;

        const isFavored = isArticleFavored(article.id, favoritArticles);
        const baseRoute = '/' + location.pathname.replace(/^\//, '').split('/')[0];

        return (
            <CellMeasurer
                cache={this.cache}
                index={index}
                key={key}
                parent={parent}
            >
                <div
                    style={{
                        ...style,
                        width: this.state.columnWidth,
                    }}
                >
                    <ArticleItem
                        userRole={this.props.userRole}
                        maxWordCount={8}
                        key={article.id}
                        id={article.id}
                        plain_text={article.plain_text}
                        title={article.title}
                        isFavored={isFavored}
                        baseRoute={baseRoute}
                        setFavoritArticles={setFavoritArticles}
                    />
                </div>
            </CellMeasurer>
        );
    }

    render() {
        const { scrollElement } = this.props;
        this._calculateColumnCount();
        this._initCellPositioner();

        return (
            <WindowScroller scrollElement={scrollElement}>
                {({ height, scrollTop }) => {
                    return height > 0 && (
                        <AutoSizer
                            disableHeight
                            //height={height}
                            //scrollTop={scrollTop}
                            onResize={this._onResize}
                        >
                            {({ width }) => (
                                <Masonry
                                    autoHeight={true}
                                    scrollTop={scrollTop}
                                    height={height}
                                    width={width}
                                    cellCount={this.props.articles.length}
                                    cellMeasurerCache={this.cache}
                                    cellPositioner={this.cellPositioner}
                                    cellRenderer={this.cellRenderer}
                                    ref={ref => this.masonryRef = ref}
                                />
                            )}
                        </AutoSizer>
                    );
                }}
            </WindowScroller>
        )
    }
}

export default withRouter(ArticleListMasonryDesktop);