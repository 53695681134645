import React, { useContext } from 'react';

import ViewportContext from '../../context/ViewportContext';
import BackgroundContext from '../../context/BackgroundContext';

import Background from '../../components/Background';

export default function RandomBackground(props){
    const { isMobile } = useContext(ViewportContext);
    const { currentBackground  } = useContext(BackgroundContext);
    const backgroundImage = isMobile ? currentBackground.mobile : currentBackground.desktop;
    return (
        <Background
            showImage={true}
            image={backgroundImage}
        />
    )
}