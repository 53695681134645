import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import AppContext from '../../context/AppContext';
import { withAppContext } from '../../context/AppContext/AppContextProvider';
import ViewportContextProvider from '../../context/ViewportContext/ViewportContextProvider';
import GlossarContextProvider from '../../context/GlossarContext/GlossarContextProvider';
import BackgroundContextProvider from '../../context/BackgroundContext/BackgroundContextProvider';

import App from '../../containers/App';
import LoginContainer from '../../containers/LoginContainer';

//import parseArticleRoute from '../../helpers/parseArticleRoute';

const AppContainer = ({location}) => {
    const {
        isLoggedIn,
        availableArticles,
        isOffcanvasOpen,
        } = useContext(AppContext);

    /*
    const { pathname } = location;

    const [bodyLocked, setBodyLocked] = useState();

    useEffect(() => {
        if (isOffcanvasOpen || parseArticleRoute(pathname) !== null) {
            setBodyLocked(true);
        } else {
            setBodyLocked(false);
        }
    }, [isOffcanvasOpen, pathname]);

    useEffect(() => {
        if (bodyLocked) {
            document.documentElement.classList.add('offcanvas-open');
        } else {
            document.documentElement.classList.remove('offcanvas-open');
        }
    }, [bodyLocked]);
    */

    useEffect(() => {
        if (isOffcanvasOpen) {
            document.documentElement.classList.add('offcanvas-open');
        } else {
            document.documentElement.classList.remove('offcanvas-open');
        }
    }, [isOffcanvasOpen]);

    return isLoggedIn !== true
        ?
        <LoginContainer />
        :
        <ViewportContextProvider>
            <BackgroundContextProvider>
                <GlossarContextProvider articles={availableArticles}>
                    <App />
                </GlossarContextProvider>
            </BackgroundContextProvider>
        </ViewportContextProvider>;
};

AppContainer.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withAppContext(withRouter(AppContainer));
