import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './_SearchResultsCount.scss';

export default class SearchResultsCount extends PureComponent {
    render() {
        const { resultsCount, searchQuery } = this.props;
        const text = searchQuery ? `Ergebnisse zu ${searchQuery}` : 'Ergebnisse gefunden';

        return (
            <div className="odt-SearchResultsCount">
                <span className="odt-SearchResultsCount__number">{resultsCount}</span>
                <span className="odt-SearchResultsCount__text">{text}</span>
            </div>
        );
    }
}

SearchResultsCount.propTypes = {
    resultsCount: PropTypes.number.isRequired,
    searchQuery: PropTypes.string
}