import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewportContext from '../../context/ViewportContext';

const BREAKPOINT_DESKTOP = 960; // 768

export default function ViewportContextProvider({children}){

    const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINT_DESKTOP);
    const [randomSeed, setRandomSeed] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < BREAKPOINT_DESKTOP);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <ViewportContext.Provider value={{
            isMobile: isMobile,
            randomSeed: randomSeed,
            setRandomSeed: setRandomSeed,
        }}
        >
            { children }
        </ViewportContext.Provider>
    )
}

ViewportContextProvider.propTypes = {
    children: PropTypes.any,
};

export function withViewport(Component){
    return (props) => {
        return(
            <ViewportContextProvider>
                <Component {...props}/>
            </ViewportContextProvider>
        );
    }
}