let _articlesById = null;

class ArticleModel {

    constructor() {
        throw new Error('ArticleModel is a static singleton.');
    }

    static newArticle(title){
        return {
            title: title,
            content: '',
            plain_text: '',
            tags: [],
            additional_links: []
        }
    }

    static findById(articleId) {
        return _articlesById && _articlesById[articleId.toString()];
    }

    static createSections = (articles) => {

        const getArticleSortTitle = title => {
            return title.toUpperCase().replace(/^([ÄÖÜ])/, char => ({'Ä':'A', 'Ö':'O', 'Ü':'U'}[char] || char));
        };

        const sortedArticles = articles.sort((a, b) => {
            const nameA = getArticleSortTitle(a.title);
            const nameB = getArticleSortTitle(b.title);
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;
            return 0;
        });

        let sections = [];
        let sectionsById = {};

        sortedArticles.map((article) => {
            const sectionId = getArticleSortTitle(article.title).substr(0,1);
            if (typeof sectionsById[sectionId] === 'undefined') {
                sectionsById[sectionId] = {
                    id: sectionId,
                    title: sectionId,
                    articles: []
                };
                sections.push(sectionsById[sectionId]);
            }
            sectionsById[sectionId].articles.push(article);
            return article;
        });

        return sections;
    };
}

export default ArticleModel;