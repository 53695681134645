import React from 'react';
import PropTypes from 'prop-types';
import backgroundImage from './images/dm-woerterbuch-background-750x1334.png';
import './_Background.scss';

export default function Background ({ showImage, image, style }) {
    const classNames = ['odt-Background'];
    if (showImage) classNames.push('has-image');
    return (
        <div className={classNames.join(' ')}>
            { showImage &&
            <div
                className="odt-Background__image"
                style={{ backgroundImage: `url(${image})`, ...style }}
            ></div>
            }
        </div>
    );
}

Background.propTypes = {
    showImage: PropTypes.bool,
    image: PropTypes.any
};

Background.defaultProps = {
    image: backgroundImage
};