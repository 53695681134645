import React, { useContext } from 'react';

import AppContext from '../../context/AppContext';

import Offcanvas from '../../components/Offcanvas';
import Filter from '../../components/Filter';
import Button from '../../components/Button';

export default function FilterContainer(props){
    const {
        isOffcanvasOpen,
        toggleOffcanvas,
        resetFilters,
        } = useContext(AppContext);

    return (
        <Offcanvas
            isOffcanvasOpen={isOffcanvasOpen}
            toggleOffcanvas={toggleOffcanvas}
        >
            <Offcanvas.Header>
                <Button
                    text
                    type="button"
                    value="Zurück"
                    icon="arrowLeft"
                    iconSize={20}
                    onClick={toggleOffcanvas}
                />
                <Button
                    text
                    type="button"
                    value="Filter zurücksetzen"
                    onClick={resetFilters}
                />
            </Offcanvas.Header>
            <Offcanvas.Content>
                <Filter />
            </Offcanvas.Content>
        </Offcanvas>
    );
};
