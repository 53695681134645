import React, { PureComponent } from 'react';
import toast from '../Toast';
import { Link } from 'react-router-dom';
import IconSvg from '../IconSvg';
import { copyToClipboard } from '../../helpers/copyToClipboard';

import './_ArticleActions.scss';
import FavorizeButton from '../FavorizeButton';

class ArticleActions extends PureComponent {
    _clickShare = (event) => {
        event.preventDefault();
        const currentUrl = window.location.href;
        copyToClipboard(currentUrl);
        toast.success(
            <div>
                <IconSvg name="checkCircle" />
                Adresse wurde in Zwischenablage kopiert.
            </div>
        );
    };

    render() {
        const { userRole, linkUpdate, isFavored } = this.props;

        if (userRole && userRole === "10") return null;

        return (
            <div className="ArticleActions">

                <Link
                    className="ArticleActions__item ArticleActions__item--create"
                    to={linkUpdate}
                >
                    <IconSvg name="pencilOutline" size={19} />
                </Link>

                <button
                    className="ArticleActions__item ArticleActions__item--share"
                    onClick={this._clickShare}
                >
                    <IconSvg name="clipboard" size={20} />
                </button>

                <FavorizeButton
                    className="ArticleActions__item ArticleActions__item--fav"
                    isFavored={isFavored}
                    size={18}
                    id={this.props.article.id}
                    submit={this.props.setFavoritArticles}
                />

            </div>
        );
    }
}

export default ArticleActions;