import { get } from "../request";

export async function getFavoriteArticles(state, setState) {
    const route = `/favorite/articles`;
    return get(state, route).then(response => {
        const { favoriteArticles } = response;
        if (!favoriteArticles){
            return;
        }
        setState({ favoritArticles: favoriteArticles.map(article => article.id) });
        console.log('Fetched favorite articles');
    });
}

export async function getUserFeedback(state, setState) {
    const route = `/user/data`;
    return get(state, route).then(response => {
        const { articleFeedbacks } = response;
        if(!articleFeedbacks){
            return;
        }
        return articleFeedbacks;
    });
}

