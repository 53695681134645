import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';

export default class FeedbackFooter extends Component {
    render() {
        const {
            isFinished,
            isLoading,
            wasHelpful,
            surveyEnd,
            step,
            selectedOptions,
            clickNext,
            clickPrev,
            cancel,
            clickSubmit
        } = this.props;

        const buttonNext = <Button secondary disabled={selectedOptions.length <= 0} onClick={clickNext}>Weiter</Button>;
        const buttonCancel = <Button disabled={isLoading} outline onClick={cancel}>Abbrechen</Button>;
        const buttonPrev = <Button secondary outline disabled={step <= 0} onClick={clickPrev}>Zurück</Button>;
        const buttonSubmit = (
            <Button
                secondary
                disabled={selectedOptions.length <= 0 || isLoading}
                value="Senden"
                isLoading={isLoading}
                onClick={clickSubmit}
            />
        );

        if (isFinished) return null;

        return (
            <div className="odt-FeedbackFooter">
                <ButtonGroup justify>
                    {(wasHelpful === false && step === 0) && buttonCancel}
                    {(wasHelpful === false && step >= 1) && buttonPrev}
                    {(wasHelpful === false && !surveyEnd) && buttonNext}
                    {(wasHelpful === false && surveyEnd) && buttonSubmit}
                </ButtonGroup>
            </div>
        );
    }
}