import React from 'react';
import PropTypes from 'prop-types';

import TagList from '../TagList';
import SearchResultsCount from '../SearchResultsCount';
import './_SearchResultsHeader.scss';
import Button from '../Button';

export default function SearchResultsHeader({
    resultsCount,
    tags,
    removeTag,
    searchQuery,
    resetFilters,
    }){
    return (
        <div className="odt-SearchResultsHeader">
            <TagList
                isRemovable
                isSelectable={false}
                tags={tags}
                remove={removeTag}
            />
            <SearchResultsCount
                resultsCount={resultsCount}
                searchQuery={searchQuery}/>
            <Button
                text
                link
                noPadding
                type="button"
                value="Filter zurücksetzen"
                onClick={resetFilters}
            />
        </div>
    );
}

SearchResultsHeader.propTypes = {
    resultsCount: PropTypes.number,
    tags: PropTypes.array,
    searchQuery: PropTypes.string,
    removeTag: PropTypes.func,
    resetFilters: PropTypes.func,
};