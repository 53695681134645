import React, { Component } from 'react';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import { FormField } from '../Form';

import './_Form.scss';

export default class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChanged: false,
            data: {
                username: '',
                password: '',
            }
        };
    }

    _onFieldChange = (event) => {
        const { data } = this.state;
        const input = event.target;
        const attr = input.getAttribute('name');
        const val = input.value;

        if( data[attr] !== val ){
            data[attr] = val;
            this.setState({
                data: data,
                isChanged: true
            });
        }
    };

    _onSubmit = (event) => {
        event.preventDefault();
        const { onSubmit } = this.props;
        if(onSubmit){
            onSubmit(this.state.data);
        }
    };
    render() {
        const { isVisible, close } = this.props;
        const classNames = [
            'odt-LoginForm',
            isVisible ? 'is-visible' : null,
        ];

        //if (!isVisible) return null;

        return (
            <form className={classNames.join(' ')} onSubmit={this._onSubmit}>
                <FormField
                    required
                    name="username"
                    type="text"
                    label="Benutzername"
                    onChange={this._onFieldChange}
                />

                <FormField
                    required
                    name="password"
                    type="password"
                    label="Passwort"
                    onChange={this._onFieldChange}
                />

                <ButtonGroup vertically>
                    <Button
                        isLoading={this.props.isPending}
                        secondary
                        type="submit"
                        value="Als Gast anmelden"
                    />
                    <Button
                        text
                        value="Abbrechen"
                        onClick={close}
                    />
                </ButtonGroup>
            </form>
        );
    }
}