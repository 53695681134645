import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router";
import { Helmet } from "react-helmet";
import PiwikReactRouter from "piwik-react-router";
import history from "./helpers/history";
import checkUrlParamsFor from './js/helpers/checkUrlParamsFor';
import AppContainer from './js/containers/AppContainer';
import * as serviceWorker from './serviceWorker';
import './scss/styles.scss';

const MATOMO_URL = process.env.REACT_APP_TRACKING_URL;
const MATOMO_SITE_ID = process.env.REACT_APP_TRACKING_ID;

const piwik = PiwikReactRouter({
    url: MATOMO_URL,
    siteId: MATOMO_SITE_ID
});

const _setTrackingLocation = () => piwik.track(history.location);

ReactDOM.render(
    <React.Fragment>
        <Helmet
            defaultTitle="dm Wörterbuch"
            titleTemplate="%s | dm Wörterbuch"
            onChangeClientState={_setTrackingLocation}
        />
        <Router history={history}>
            <AppContainer />
        </Router>
    </React.Fragment>,
    document.getElementById('odt-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if ( checkUrlParamsFor( 'noWorker' ) ) {
    console.log('[ServiceWorker disabled]');
    serviceWorker.unregister();
} else {
    console.log('[ServiceWorker enabled]');
    serviceWorker.register();
}
