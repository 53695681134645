import { get } from "../request";

export async function fetchTags(state, setState){
    const route = `/tags`;
    return get(state, route).then(response => {
        const { tags } = response;
        if(!tags){
            return;
        }
        setState({ availableTags: tags })
        console.log('Fetched tags');
    });
}