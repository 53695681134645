import Fuse from 'fuse.js';

/**
 *
 * @param {Object} state
 * @param {Function} setState
 * @param {Object} prevProps
 * @param {Object} prevState
 *
 * Handels the Filtering of Articles by Searchbar and Tags
 */

export function getFilteredArticles(articles, tags, query) {
    const options = {
        sort: true,
        threshold: 0.1,
        location: 0,
        distance: (query.length > 2 ? 100 : 1),
        maxPatternLength: 32,
        minMatchCharLength: 0,
        keys: [
            "title"
        ],
    };

    const fuse = new Fuse(articles, options);
    const fuseSearchResult = fuse.search(query);
    const fuseList = fuse.list;
    let articlesToSearchIn = null;

    if (
        query && query.length > 0
        && tags && tags.length > 0
    ) {
        articlesToSearchIn = fuseSearchResult;
    }
    else if (query && query.length > 0) {
        articlesToSearchIn = fuseSearchResult;
    }
    else if (tags && tags.length > 0) {
        articlesToSearchIn = fuseList;
    }

    /**
     * only show search results when tags are matching
     * return only the IDs
     */
    const result = articlesToSearchIn.filter(article => tags.every(tag => article.tags.indexOf(tag) !== -1)).map(article => article.id);

    return result;
}

export function handleArticleFiltering(state, setState, prevProps, prevState) {
    if (
        state.searchQuery !== prevState.searchQuery
        || state.selectedTags !== prevState.selectedTags
        || state.locallySelectedTags !== prevState.locallySelectedTags
    ) {
        let filteredArticles = null;
        let locallyFilteredArticles = null;

        if (state.searchQuery.length > 0 || state.selectedTags.length > 0) {
            filteredArticles = getFilteredArticles(state.availableArticles, state.selectedTags, state.searchQuery);
        }

        if (state.locallySelectedTags.length <= 0 && state.searchQuery.length <= 0) {
            locallyFilteredArticles = [];
        } else {
            locallyFilteredArticles = getFilteredArticles(state.availableArticles, state.locallySelectedTags, state.searchQuery);
        }

        if (state.searchQuery !== prevState.searchQuery || state.selectedTags !== prevState.selectedTags) {
            setState({
                filteredArticles: filteredArticles,
                locallyFilteredArticles: filteredArticles
            });
        }

        if (state.locallySelectedTags !== prevState.locallySelectedTags) {
            setState({
                locallyFilteredArticles: locallyFilteredArticles
            });
        }
    }
}