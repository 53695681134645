import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ArticleActions from './actions';
import ArticleTags from './tags';
import Feedback from '../Feedback';
import Headline from '../Headline';
import Paragraph from '../Paragraph';
import Button from '../Button';
import LayoutContainer from '../LayoutContainer';
import './_ArticleDetails.scss';

class ArticleDetails extends PureComponent {
    constructor() {
        super();
        this.state = {
            alreadyFeedbacked: null
        }
        this.handleLinkClicking = this.handleLinkClicking.bind(this);
    }

    componentDidMount() {
        //window.scrollTo(0, 0);
        window.addEventListener('click', this.handleLinkClicking, false);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleLinkClicking);
    }

    handleLinkClicking(event) {
        // Only run for iOS full screen apps
        if (('standalone' in window.navigator) && window.navigator.standalone) {

            // Only run if link is an anchor and points to the current page
            if (
                event.target.tagName.toLowerCase() !== 'a'
                || event.target.hostname !== window.location.hostname
                || event.target.pathname !== window.location.pathname
                || !/#/.test(event.target.href)
            ) {
                return;
            }

            // Open link in same tab
            event.preventDefault();
            window.location = event.target.href;
        }
    }

    render() {
        const { userRole, history, article, availableTags, baseRoute, displayActions, displayFeedback, isFavored } = this.props;

        return (
            <div className="ArticleDetails" id={`ArticleView-${article.id}`}>
                <Helmet>
                    <title>{article.title}</title>
                </Helmet>
                <LayoutContainer hasPadding={false} extraSmall>
                    <div className="ArticleDetails-content">
                        <div className="ArticleDetails-header">
                            {
                                displayActions
                                    &&
                                    <Button
                                        text
                                        noPadding
                                        type="link"
                                        value="Zurück"
                                        icon="arrowLeft"
                                        iconSize={20}
                                        to={baseRoute}
                                    />
                            }
                            <Headline as="h1" size="h5" className="ArticleDetails__title">{article.title}</Headline>
                            {
                                displayActions
                                    &&
                                    <ArticleActions
                                        userRole={userRole}
                                        linkUpdate={`${baseRoute}/article/update/${article.id}`}
                                        history={history}
                                        article={article}
                                        isFavored={isFavored}
                                        setFavoritArticles={this.props.setFavoritArticles}
                                    />
                            }
                        </div>

                        <div className="ArticleDetails-description">
                            <Paragraph size="body1" as="div">
                                <p dangerouslySetInnerHTML={{__html: article.content}}/>
                            </Paragraph>
                        </div>

                        <ArticleTags
                            article={article}
                            availableTags={availableTags}
                        />

                        {
                            displayFeedback
                                &&
                                <Feedback
                                    userRole={userRole}
                                    id={article.id}
                                    alreadyFeedbacked={this.state.alreadyFeedbacked}
                                    linkUpdate={`${baseRoute}/article/update/${article.id}`}
                                    submitArticleFeedback={this.props.submitArticleFeedback}
                                />
                        }
                    </div>
                </LayoutContainer>
            </div>
        );
    }
}


ArticleDetails.propTypes = {
    baseRoute: PropTypes.string,
    article: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        tags: PropTypes.array,
        additional_links: PropTypes.array
    }).isRequired,
    displayActions: PropTypes.bool,
    displayFeedback: PropTypes.bool,
    isFavored: PropTypes.bool,
};

ArticleDetails.defaultProps = {
    displayActions: true,
    displayFeedback: true
};

export default withRouter(ArticleDetails);