import React, { useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';
import BackgroundContext from '../../context/BackgroundContext';

import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import LayoutContainer from '../LayoutContainer';
import ArticleListView from '../ArticleListView';

import './_RandomArticles.scss';

export default function RandomArticles({articles, showButton}){
    const {
        setRandomArticles,
        } = useContext(AppContext);
    const {
        setRandomBackground,
        } = useContext(BackgroundContext);

    const scrollContainerRef = useRef();

    const handleClickSetRandom = useCallback(() => {
        setRandomArticles();
        setRandomBackground();
    }, [setRandomArticles, setRandomBackground]);

    return (
        <div className="odt-RandomArticles" ref={scrollContainerRef}>
            <div className="odt-RandomArticles__inner">
                { articles &&
                <LayoutContainer medium>
                    { articles.length > 0 &&
                    <ArticleListView
                        articles={articles}
                        scrollElement={scrollContainerRef.current}
                    />
                    }
                    { articles.length === 0 &&
                    <LoadingIndicator />
                    }
                </LayoutContainer>
                }
            </div>

            { showButton &&
            <Button
                secondary
                floating
                icon="dice"
                iconSize={20}
                onClick={handleClickSetRandom}
            >
                Zufallsgenerator
            </Button>
            }

        </div>

    );
}

RandomArticles.propTypes = {
    articles: PropTypes.array,
    showButton: PropTypes.bool,
};

RandomArticles.defaultProps = {
    showButton: true,
};