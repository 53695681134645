import { post } from "../request";

export function submitArticleSuggestion(articleId, data, state, setState) {
    let route = `/article/suggestion`;
    if(articleId){
        route = `${route}/${articleId}`;
    }
    return post(state, route, data).then(response => {
        const { suggestion } = response;
        if(!suggestion){
            return;
        }

        console.log('submit article suggestion', response);
        return response;
    });
}
