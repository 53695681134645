import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import './_GlossaryListItem.scss';

class GlossaryListItem extends PureComponent {

    render() {
        const {id, title} = this.props;

        return (
            <div key={id} className="GlossaryListItem">
                <NavLink className="GlossaryListItem__link" to={`/glossar/article/view/${id}`}>{title}</NavLink>
            </div>
        );
    }
}

export default GlossaryListItem;
