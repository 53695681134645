import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './_Card.scss';

export default class Card extends Component {
    render() {
        const { isClickable, big } = this.props;
        const baseName = "odt-Card";
        const classNames = [
            baseName,
            isClickable ? "is-clickable" : "",
            big ? `${baseName}--big` : "",
        ];

        return (
            <div
                className={classNames.join(' ')}
            >
                <div className="odt-Card__inner">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Card.propTypes = {
    isClickable: PropTypes.bool,
    big: PropTypes.bool,
}

Card.defaultProps = {
    isClickable: true,
    big: false,
}