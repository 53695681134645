import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import AppContext from '../../context/AppContext';
import parseArticleRoute from '../../helpers/parseArticleRoute';

import ArticleDetails from '../ArticleDetails';
import ArticleEditor from '../ArticleEditor';
import NotFound from '../NotFound';
import LoadingIndicator from '../LoadingIndicator';
import RandomBackground from '../RandomBackground';

import { isArticleFavored } from '../../helpers/isArticleFavored';
import ArticleModel from '../../models/ArticleModel';

import './_ArticleRouter.scss';

/**
 * @returns {*|null}
 */
function ArticleRouter({location}){

    const { pathname } = location;
    const {
        userRole,
        availableArticles,
        availableTags,
        favoritArticles,
        searchQuery,
        getUserFeedback,
        setFavoritArticles,
        submitArticleFeedback,
        submitArticleSuggestion,
        } = useContext(AppContext);

    const articleRoute = parseArticleRoute(pathname);
    if(!articleRoute){
        return null;
    }

    const { baseRoute, action, articleId } = articleRoute;
    const isGuest = userRole === '10';
    const isReady = !!(availableArticles && availableArticles.length);

    let article = null;
    let isFavored = false;

    if(isReady){
        switch(action){
            case 'view':
            case 'update':
                if(articleId){
                    article = availableArticles.filter(entry => entry.id.toString() === articleId)[0];
                }
                break;
            case 'create':
                article = ArticleModel.newArticle(searchQuery);
                break;
            default:
                break;
        }
        if(favoritArticles && article && article.id){
            isFavored = isArticleFavored(article.id, favoritArticles);
        }
    }

    return !isReady
        ?
        <LoadingIndicator/>
        :
        <div className="odt-ArticleRouter">

            <RandomBackground />

            { article &&
            <Switch>
                <Route path={`${baseRoute}/article/view/:id`}>
                    <ArticleDetails
                        displayFeedback
                        userRole={userRole}
                        baseRoute={baseRoute}
                        article={article}
                        availableTags={availableTags}
                        isFavored={isFavored}
                        getUserFeedback={getUserFeedback}
                        setFavoritArticles={setFavoritArticles}
                        submitArticleFeedback={submitArticleFeedback}
                    />
                </Route>

                { !isGuest &&
                <Route path={`${baseRoute}/article/update/:id`}>
                    <ArticleEditor
                        article={article}
                        availableTags={availableTags}
                        isFavored={isFavored}
                        baseRoute={baseRoute}
                        submitArticleSuggestion={submitArticleSuggestion}
                    />
                </Route>

                }
                { !isGuest &&
                <Route path={`${baseRoute}/article/create`}>
                    <ArticleEditor
                        article={article}
                        availableTags={availableTags}
                        isFavored={isFavored}
                        baseRoute={baseRoute}
                        submitArticleSuggestion={submitArticleSuggestion}
                    />
                </Route>
                }

                <Route component={NotFound}/>
            </Switch>
            }

            { !article &&
                <NotFound />
            }
        </div>;
}

export default ArticleRouter;