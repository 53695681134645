import React, { Component } from 'react';
import './_Offcanvas.scss';

export default class Offcanvas extends Component {
    static Header = ({children}) => <div className="odt-Offcanvas__header">{children}</div>;
    static Content = ({children}) => <div className="odt-Offcanvas__content">{children}</div>;

    render() {
        const { isOffcanvasOpen } = this.props;
        const classNames = ['odt-Offcanvas'];
        if (isOffcanvasOpen) classNames.push('is-open');

        return (
            <div className={classNames.join(' ')}>
                <div className="odt-Offcanvas__body" ref={body => this.body = body}>
                    {this.props.children}
                </div>
                <div className="odt-Offcanvas__overlay" onClick={this.props.toggleOffcanvas} />
            </div>
        );
    }
}