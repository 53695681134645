import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppContext from '../../context/AppContext';
import ViewportContext from '../../context/ViewportContext';

import LoginContainer from '../../containers/LoginContainer';
import SearchContainer from '../../containers/SearchContainer';
import GlossarContainer from '../../containers/GlossarContainer';
import FavoritesContainer from '../../containers/FavoritesContainer';
import FilterContainer from '../../containers/FilterContainer';
import Toolbar from '../../containers/Toolbar';

import RandomBackground from '../../components/RandomBackground';
import ArticleRouter from '../../components/ArticleRouter';
import NotFound from '../../components/NotFound';
import './_App.scss';

const App = (props) => {

    const {
        isLoggedIn,
        userRole,
        } = useContext(AppContext);
    const {
        isMobile,
        } = useContext(ViewportContext);

    return isLoggedIn !== true
        ?
        <LoginContainer />
        :
        <div className="odt-App">
            <ToastContainer
                position={isMobile ? 'top-right' : 'bottom-right'}
                autoClose={4000}
                hideProgressBar={true}
            />

            <RandomBackground />
            <Toolbar />

            <Switch>
                <Route path="/login" component={LoginContainer}/>
                <Route path='/search' component={SearchContainer}/>
                <Route path='/glossar' component={GlossarContainer}/>

                { userRole !== "10" &&
                <Route path='/favorites'
                       render={routeProps => (
                        <FavoritesContainer
                            routeProps={routeProps}
                            baseRoute={'/favorites'}
                        />
                    )}
                />
                }
                <Redirect exact from='/' to='/search' />
                <Route component={NotFound} />
            </Switch>

            <Switch>
                <Route path="/(search|glossar|favorites)/article/(view|update|create)/:id?"
                       component={ArticleRouter}/>
            </Switch>

            <FilterContainer />

        </div>;
};

export default App;