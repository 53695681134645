import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import getUrlParam from '../../helpers/getUrlParam';
import Button from '../Button';
import Headline from '../Headline';
import ButtonGroup from '../ButtonGroup';
import Logo from '../Logo';
import Background from '../Background';
import LoginForm from './form';
import toast from '../Toast';
import backgroundImage from '../../../assets/images/dm-woerterbuch-background-login.svg';
import './_Login.scss';

export default class Login extends Component {
    constructor(props) {
        super(props);


        this.cleanUri = window.location.protocol + "//" + window.location.host + window.location.pathname;
        this.tokenKey = 'accessToken';
        this.loginUrl = `${process.env.REACT_APP_API_URL}/saml/login`;

        this.state = {
            isDmLoginPending: false,
            isPending: false,
            isFormOpen: false,
        };

        this.handleAuth = this.handleAuth.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.openForm = this.openForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.handleAuth();
    }

    handleAuth() {
        const urlToken = getUrlParam(this.tokenKey);
        const storageToken = window.sessionStorage.getItem('accessToken');
        const storageTokenExpiresAt = window.sessionStorage.getItem('accessTokenExpiresAt');
        const localUserRole = window.sessionStorage.getItem('userRole');

        console.log('[login] Init');

        if (urlToken) {
            console.log('[login] Retrieved token from url');
            window.history.replaceState({}, document.title, this.cleanUri);
            this.props.setUserRole();
            this.props.setAccessToken(urlToken);
            return;
        }

        if (storageToken && storageTokenExpiresAt) {
            const expiresAt = parseInt(storageTokenExpiresAt);
            console.log('[login] Found token and token expiration in storage');
            if (!isNaN(expiresAt) && expiresAt > new Date().getTime()) {
                console.log('[login] Stored token is valid until', new Date(expiresAt).toLocaleString());
                this.props.setUserRole(localUserRole);
                this.props.setAccessToken(storageToken);
            } else {
                console.log('[login] Stored token has expired and will be deleted');
                window.sessionStorage.removeItem('accessToken');
                window.sessionStorage.removeItem('accessTokenExpiresAt');
            }
        }
    }

    onLoginClick() {
        //this.setState({ isDmLoginPending: true });
        window.location.href = this.loginUrl;
    }

    openForm() {
        this.setState({ isFormOpen: true });
    }

    closeForm() {
        this.setState({ isFormOpen: false });
    }

    _onSubmitLogin = (data) => {
        const { login } = this.props;

        console.log('[login] Guest login');

        this.setState({ isPending : true });

        login(data).then(response => {
            console.log('[login] Retrieved token');
            this.setState({ isPending : false });
            this.props.setUserRole(response.user.role);
            this.props.setAccessToken(response.user.accessToken);
            window.history.replaceState({}, document.title, this.cleanUri);
        }).catch(error => {
            console.error('[login] An Error accured: ', error);
            this.setState({ isPending : false });
            toast.error('Anmeldung Fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten.');
        });
    };

    render() {
        return (
            <div className="odt-Login">
                <Helmet>
                    <title>Login</title>
                </Helmet>

                <div className="odt-Login__inner">

                    <div className="odt-Login__header">
                        <Logo />
                        <Headline
                            className="odt-Login__title"
                            as="h1"
                            size="h6"
                        >
                            Abkürzungen und Fachbegriffe aus dem Arbeitsalltag bei dm
                        </Headline>

                        {
                            !this.state.isFormOpen
                                &&
                                <ButtonGroup vertically>
                                    <Button
                                        secondary
                                        value="Mit dm Login anmelden"
                                        onClick={this.onLoginClick}
                                    />
                                    <Button
                                        outline
                                        light
                                        value="Als Gast anmelden"
                                        onClick={this.openForm}
                                    />
                                </ButtonGroup>
                        }
                    </div>

                    <div className="odt-Login__body">

                        <LoginForm
                            isPending={this.state.isPending}
                            isVisible={this.state.isFormOpen}
                            onSubmit={this._onSubmitLogin}
                            close={this.closeForm}
                        />

                    </div>

                </div>
                <Background showImage image={backgroundImage} />
            </div>
        );
    }
}
