import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagList from '../TagList';
import TagListSearchableLight from '../TagListSearchableLight';

class TagsField extends Component {
    _toggleTags = (clickedTag) => {
       this.props.onChange(clickedTag)
    };

    render() {
        const { availableTags, tags } = this.props;
        let selectedTags = availableTags && availableTags.filter((tag) => {
            return tags.indexOf(tag.id) !== -1;
        });

        return (
            <div className="Form-control">
                <TagList
                    tags={selectedTags}
                    hasPadding={false}
                    isSelectable={false}
                    isRemovable={true}
                    remove={this._toggleTags}
                    style={{ marginBottom: 20}}
                />
                <TagListSearchableLight
                    flatList
                    tags={availableTags}
                    selectedTags={selectedTags}
                    inputPlaceholder={this.props.placeholder}
                    clickTag={this._toggleTags}
                    onChange={e => console.log(e)}
                />
            </div>
        );
    }
}

TagsField.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

TagsField.defaultProps = {
    placeholder: 'Tags hinzufügen'
}

export default TagsField;