/**
 *
 * @param {Array of IDs} tags
 * @param {ReactJS state object} state
 *
 * Get the articles which include the selected tags
 * Extract only the article ID
 */

export function getArticlesByTagId( articles, tags, state ) {
    const articlesWithTags = articles.filter(article => tags.every(tagId => article.tags.indexOf(tagId) !== -1)).map(article => article.id);

    return articlesWithTags
}

export function getSomeArticlesByTagId( articles, tags, state ) {
    const articlesWithTags = articles.filter(article => tags.some(tagId => article.tags.indexOf(tagId) !== -1)).map(article => article.id);

    return articlesWithTags
}