import React from 'react';
import PropTypes from 'prop-types';
import GlossaryList from '../GlossaryList';

import './_Glossary.scss';

export default function Glossary({ sections, activeSectionId, onFocusSection, locked}){
    return (
        <div className="odt-Glossary">
            <GlossaryList
                sections={sections}
                locked={locked}
                focusSectionId={activeSectionId}
                onFocusSection={onFocusSection}
            />
        </div>
    );

}

Glossary.propTypes = {
    sections: PropTypes.array,
    activeSectionId: PropTypes.any,
    onFocusSection: PropTypes.func,
    locked: PropTypes.bool,
};

Glossary.defaultProps = {
    onFocusSection: () => {},
};