import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormButtons, FormHeader } from '../Form';
import ArticleDetails from '../ArticleDetails';
import LayoutContainer from '../LayoutContainer';
import Headline from '../Headline';
import './_ArticlePreview.scss';

class ArticlePreview extends PureComponent {

    render(){
        const {article, availableTags, formTitle} = this.props;

        return (
            <div className="ArticlePreview">
                <div className="ArticlePreview-header">
                    <LayoutContainer extraSmall>
                        <FormButtons {...this.props} labelSubmit="Vorschlag senden" />
                        {formTitle && <FormHeader><Headline size="subtitle3">{formTitle}</Headline></FormHeader>}
                    </LayoutContainer>
                </div>

                <div className="ArticlePreview-content">
                    {
                        article
                        &&
                            <ArticleDetails
                                article={article}
                                availableTags={availableTags}
                                displayFeedback={false}
                                displayActions={false}
                            />
                    }
                </div>
            </div>
        );
    }
}

ArticlePreview.propTypes = {
    formTitle: PropTypes.string,
    article: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        tags: PropTypes.array
    })
};

ArticlePreview.defaultProps = {
    formTitle: 'Vorschau'
};

export default ArticlePreview;