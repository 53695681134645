import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPause from '@allpro/react-router-pause';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ArticleEditorMode from './mode';
import ArticleForm from './form';
import ArticlePreview from './preview';
import ArticleChangelog from './changelog';
import LoadingIndicator from '../LoadingIndicator';
import Background from '../Background';
import toast from '../Toast';
import IconSvg from '../IconSvg';
import backgroundImage from '../../../assets/images/dm-woerterbuch-background-editor.svg';
import Prompt from '../Prompt';
import LayoutContainer from '../LayoutContainer';
import './_ArticleEditor.scss';


class ArticleEditor extends PureComponent {

    constructor(props){
        super(props);
        this.navigation = null;
        this.state = {
            mode: ArticleEditorMode.form,
            editorModel: null,
            changes: null,
            isModified: false,
            isCancelled: false,
            isSaved: false,
            isPending: false,
            showDialog: false,
        };
        this.handleNavigationAttempt = this.handleNavigationAttempt.bind(this);
        this.handleStay = this.handleStay.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
    }

    componentDidMount() {
        if(!this.state.editorModel){
            this.setState({
                editorModel: this._createModel()
            });
        }
        window.addEventListener('beforeunload', this._onBeforeunload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this._onBeforeunload);
    }

    _onBeforeunload = (event) => this.handleNavigationAttempt(null, event);

    _createModel = (data) => {
        const { article } = this.props;

        const editorModel = Object.assign({
            title: article.title,
            content: article.content,
            plain_text: article.plain_text,
            tags: article.tags,
        }, data);

        if( article.id ){
            editorModel.id = article.id;
        }
        return editorModel;
    };

    _onFormChange = (data) => {
        //console.log('_onFormChange', data);
        this.setState({
            isModified: true,
            isValid: this._validate(data)
        });
    };

    _submitForm = (data) => {
        console.log(data);
        if(this._validate(data)){
            this.setState({
                editorModel: this._createModel(data),
            }, () => {
                //this.showPreview();
                this.send();
            });
        }
    };

    _submitPreview = () => {
        const { article } = this.props;
        const isNew = !article.id;
        if(isNew){
            this.send();
        } else {
            //this.showChangelog();
            this.send();
        }
    };

    _submitChangelog = (changes) => {
        this.setState({
            changes: changes
        }, () => {
            this.send();
        });
    };

    _validate = (data) => {
        if(!data.title || !data.title.length){
            return false;
        } else if(!data.content || !data.content.length || data.content.toLowerCase().replace(/[\s]/g) === '<p></p>'){
            return false;
        }
        return true;
    };

    cancel(){
        if (!this.state.isCancelled){
            this.setState({ isCancelled: true });
        }
    }

    showForm(){
        this.setMode(ArticleEditorMode.form);
    }

    showPreview(){
        this.setMode(ArticleEditorMode.preview);
    }

    showChangelog(){
        this.setMode(ArticleEditorMode.changelog);
    }

    setMode(mode){
        if(mode && this.state.mode !== mode){
            this.setState({mode: mode});
        }
    }

    send() {
        const { article, submitArticleSuggestion } = this.props;
        const { editorModel, changes } = this.state;
        const reloadButton = window.navigator.onLine ? <button onClick={() => { window.location.reload(); window.sessionStorage.clear(); }}><IconSvg name="reload" caption="Neu laden" /></button> : null;
        const errorMessage = (
            <div className="toast__columns">
                {
                    navigator.onLine ?
                        'Etwas ist schief gelaufen. Bitte probieren Sie es erneut.'
                        : 'Bitte überprüfen Sie Ihre Internetverbindung.'
                }
                {reloadButton}
            </div>
        );

        if(!editorModel) return;

        const isNew = !article.id;

        let suggestion = Object.assign(editorModel, {
            tag_ids: editorModel.tags.join(','),
        });

        if(!isNew && changes){
            suggestion.changes = changes.join(',');
        }

        this.setState({
            isPending: true,
            suggestion: suggestion,
        });

        submitArticleSuggestion(article.id, suggestion)
            .then(response => {
                const { suggestion } = response;
                let data = { isPending: false };
                data.suggestion = suggestion;
                if(suggestion.id){
                    data.isSaved = true;
                }
                this.setState(data);
            })
            .catch(() => {
                toast.error(errorMessage);
                this.setState({ isPending: false });
            })
    }

    handleNavigationAttempt(navigation, event) {
        if (!this.state.isModified) {
            this.cancel();
            return;
        }

        if (event) {
            if (!this.state.isModified) return;
            event.preventDefault();
            event.returnValue = '';
            return;
        }

        this.navigation = navigation;
        this.setState({ showDialog: true });

        return null;
    }

    closeDialog() {
        this.setState({ showDialog: false })
    }

    handleStay() {
        this.closeDialog();
        if (!this.navigation) return;
        this.navigation.cancel()
    }

    handleLeave() {
        this.closeDialog();
        if (!this.navigation) {
            this.cancel();
            return;
        };
        this.navigation.resume();
   }

    render() {
        const { mode, isPending, isCancelled, isSaved } = this.state;
        const { article, baseRoute } = this.props;
        const isNew = !article.id;
        const linkBack = (article && article.id) ? `${baseRoute}/article/view/${article.id}` : baseRoute;
        const successMessage = <div><IconSvg name="checkCircle" />Vielen Dank!<br/>Ihr Vorschlag wird geprüft.</div>;

        if (isCancelled) {
            return <Redirect to={{ pathname: linkBack }}/>;
        }

        if (isSaved && isNew) {
            toast.success(successMessage);
            return <Redirect to={'/'} />;
        }

        if (isSaved) {
            toast.success(successMessage);
            return <Redirect to={{ pathname: linkBack }}/>;
        }

        const showBackground = false;

        return (
            <div className="ArticleEditor" data-mode={mode}>
                <Helmet>
                    <title>Begriff vorschlagen</title>
                </Helmet>

                <ReactRouterPause
                    handler={navigation => this.handleNavigationAttempt(navigation)}
                    when={this.state.isModified}
                />

                <Prompt
                    open={this.state.showDialog}
                    config={{ confirm: `Seite verlassen` }}
                    onClickAbort={this.handleStay}
                    onClickConfirm={this.handleLeave}
                >
                    Bitte beachten Sie, dass Ihre Änderungen nicht gespeichert werden.
                </Prompt>

                { showBackground &&
                <Background showImage image={backgroundImage} />
                }
                <LayoutContainer hasPadding={false} extraSmall>
                    <div className="ArticleEditor-content">
                        { this.renderForm() }
                        { this.renderPreview() }
                        { this.renderChangelog() }
                        { isPending && <LoadingIndicator /> }
                    </div>
                </LayoutContainer>

            </div>
        )
    }

    renderForm(){
        const { mode, editorModel, isModified, isValid, isCancelled, isSaved } = this.state;
        if( isCancelled || isSaved || mode !== ArticleEditorMode.form ){
            return null;
        }
        if( !editorModel ){
            return null;
        }
        const onCancel = () => {
            this.handleNavigationAttempt();
        };
        return (
            <ArticleForm
                isPending={this.state.isPending}
                article={editorModel}
                availableTags={this.props.availableTags}
                onChange={this._onFormChange}
                onSubmit={this._submitForm}
                onCancel={onCancel}
                canSubmit={isModified && isValid}
            />
        );
    }

    renderPreview(){
        const { mode, editorModel } = this.state;
        if( mode !== ArticleEditorMode.preview ){
            return null;
        }
        const onCancel = () => {
            this.showForm();
        };
        return (
            <ArticlePreview
                isPending={this.state.isPending}
                article={editorModel}
                availableTags={this.props.availableTags}
                onSubmit={this._submitPreview}
                onCancel={onCancel}
                labelCancel="Zurück"/>
        );
    }

    renderChangelog(){
        const { mode } = this.state;
        if( mode !== ArticleEditorMode.changelog ){
            return null;
        }
        const onCancel = () => {
            this.showPreview();
        };
        return (
            <ArticleChangelog
                isPending={this.state.isPending}
                onSubmit={this._submitChangelog}
                onCancel={onCancel}
                labelSubmit="Fertig"
            />
        );
    }
}

ArticleEditor.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        tags: PropTypes.array,
        additional_links: PropTypes.array
    }),
    baseRoute: PropTypes.string
};

export default ArticleEditor;
