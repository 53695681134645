import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import ButtonGroup from '../ButtonGroup';

class FormButtons extends PureComponent {

    render(){
        const {isPending, labelCancel, labelSubmit, onCancel, onSubmit, disabled} = this.props;
        return (
            <div className="Form-buttons">
                <ButtonGroup justify>
                    <Button
                        disabled={isPending}
                        outline
                        onClick={onCancel}
                    >
                        {labelCancel}
                    </Button>
                    <Button
                        secondary
                        isLoading={this.props.isPending}
                        type="submit"
                        disabled={disabled || isPending}
                        onClick={onSubmit}
                    >
                        {labelSubmit}
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

FormButtons.propTypes = {
    labelCancel: PropTypes.string,
    labelSubmit: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool
};

FormButtons.defaultProps = {
    labelCancel: 'Zurück',
    labelSubmit: 'Weiter',
    disabled: false
};

export default FormButtons;