import React, { Component } from 'react';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Headline from '../Headline';
import './_FeedbackHeader.scss';

export default class FeedbackHeader extends Component {
    render() {
        const {
            wasHelpful,
            isFinished,
            isLoading,
            clickYes,
            clickNo
        } = this.props;

        if (isFinished || wasHelpful === false) return null;

        return (
            <div className="odt-FeedbackHeader">
                <Headline size="subtitle3" className="odt-FeedbackHeader__title">Diese Erklärung war hilfreich</Headline>
                <div className="ArticleFeedback__buttons">
                    <ButtonGroup>
                        <Button
                            outline
                            disabled={isLoading}
                            isLoading={wasHelpful && isLoading}
                            value='Ja'
                            onClick={clickYes}
                        />
                        <Button
                            outline
                            disabled={isLoading}
                            isSelected={wasHelpful === false}
                            onClick={clickNo}
                        >
                            Nein
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}