import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ArticleModel from '../../models/ArticleModel';
import GlossarContext from '../../context/GlossarContext';

export default function GlossarContextProvider({articles, children}){

    const [activeSectionId, setActiveSectionId] = useState(null);
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if(articles && articles.length){
            const sections = ArticleModel.createSections(articles);
            setSections(sections);
        }
    }, [articles]);

    useEffect(() => {
        if(sections && sections.length && !activeSectionId){
            setActiveSectionId(sections[0].id);
        }
    }, [sections, activeSectionId]);

    return (
        <GlossarContext.Provider value={{
            articles: articles,
            sections: sections,
            activeSectionId: activeSectionId,
            setActiveSectionId: setActiveSectionId,
        }}
        >
            { children }
        </GlossarContext.Provider>
    )
}

GlossarContextProvider.propTypes = {
    children: PropTypes.any,
};


export function withGlossarContext(Component){
    return (props) => {
        return(
            <GlossarContextProvider>
                <Component {...props}/>
            </GlossarContextProvider>
        );
    }
}