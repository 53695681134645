import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import getElementType from '../../helpers/getElementType';
import './_Paragraph.scss';

export default class Paragraph extends PureComponent {
    render() {
        const {
            className,
            style,
            center,
            size,
            children
        } = this.props;

        const ElementType = getElementType(Paragraph, this.props)
        const compName = 'odt-Paragraph';
        const classNames = [
            compName,
            `${compName}--${size}`,
            center ? `${compName}--center` : '',
            className
        ];

        return (
            <ElementType className={classNames.join(' ')} style={style}>
                {children}
            </ElementType>
        );
    }
}

Paragraph.propTypes = {
    /** An element type to render as string. */
    as: PropTypes.oneOf(['p', 'div', 'span']),

    /** A string added to the classNames */
    size: PropTypes.oneOf(['body1', 'body2']),
}

Paragraph.defaultProps = {
    as: 'p',
    size: 'body1'
}