import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';

import LayoutContainer from '../LayoutContainer';
import ArticleListView from '../ArticleListView';
import SearchNotFound from './SearchNotFound';
import SearchResultsHeader from './SearchResultsHeader';
import './_SearchResults.scss';

export default function SearchResults({articles, tags, removeTag, baseRoute}){
    const {
        availableArticles,
        resetFilters,
        searchQuery,
        } = useContext(AppContext);

    const scrollContainerRef = useRef();

    const searchHeader = articles ?
        <SearchResultsHeader
            resultsCount={articles.length}
            searchQuery={searchQuery}
            tags={tags}
            removeTag={removeTag}
            resetFilters={resetFilters}
        /> : null;

    return (
        <div className="odt-SearchResults" ref={scrollContainerRef}>
            <div className="odt-SearchResults__inner">
                { articles &&
                <LayoutContainer medium>
                    { articles.length > 0 &&
                    <ArticleListView
                        articles={articles}
                        scrollElement={scrollContainerRef.current}
                        renderHeader={searchHeader}
                    />
                    }
                    { articles.length === 0 &&
                    <SearchNotFound
                        baseRoute={baseRoute}
                        availableArticles={availableArticles}
                        searchQuery={searchQuery}
                        renderHeader={searchHeader}
                    />
                    }
                </LayoutContainer>
                }
            </div>
        </div>
    );
}

SearchResults.propTypes = {
    baseRoute: PropTypes.string,
    articles: PropTypes.array,
    tags: PropTypes.array,
    removeTag: PropTypes.func,
};

SearchResults.defaultProps = {
    removeTag: () => {},
};