import React, { PureComponent } from 'react';
import logo from './dm-az-logo.svg';

export default class Logo extends PureComponent {
    render() {
        return (
            <div className="odt-Logo" style={{ userSelect: 'none' }}>
                <img
                    className="odt-Login__logo"
                    src={logo}
                    alt="dm Wörterbuch Logo"
                    style={{
                        flex: '1 1',
                        maxWidth: '100%'
                    }}
                />
            </div>
        );
    }
}