import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppContext from '../../context/AppContext';
import GlossarContext from '../../context/GlossarContext';
import useReactRouter from '../../hooks/useReactRouter';

import Logo from '../../components/Logo';
import IconSvg from '../../components/IconSvg';
import LayoutContainer from '../../components/LayoutContainer';
import Searchbar from '../../components/Searchbar';
import GlossaryNav from '../../components/GlossaryNav';
import ToolbarItem from './item';

import './_Toolbar.scss';

const toolbarItems = [
    {
        id: 'search',
        to: '/search',
        icon: 'search',
        controls: true,
    },
    {
        id: 'glossar',
        to: '/glossar',
        icon: 'alphabet',
        controls: true,
    },
    {
        id: 'favorites',
        to: '/favorites',
        icon: 'heartOutline',
        controls: false,
    },
];

const baseClassName = 'odt-Toolbar';


export default function Toolbar({changeRouteOnOpen, closeOnChangeRoute}){

    const {
        userRole,
        searchQuery,
        setSearchQuery,
        toggleOffcanvas,
        resetFilters,
        } = useContext(AppContext);

    const {
        sections,
        activeSectionId,
        setActiveSectionId,
        } = useContext(GlossarContext);

    const {
        history,
        location,
        } = useReactRouter();

    const { pathname } = location;

    // state
    const [controlsOpen, setControlsOpen] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [items, setItems] = useState([]);


    const handleClickItem = useCallback((item, event) => {
        if(item.controls){
            if(history.location.pathname === item.to){
                event.preventDefault();
            } else if(!changeRouteOnOpen){
                event.preventDefault();
            }
        }
        setActiveId(item.id);
        setControlsOpen(item.controls);
    }, [history, changeRouteOnOpen]);

    const closeControls = useCallback(() => {
        setActiveId(null);
        setControlsOpen(false);
    }, []);

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        if(query){
            if(history.location.pathname !== '/search'){
                history.push('/search');
            }
        }
    }, [history, setSearchQuery]);

    const handleGlossaryNav = useCallback((sectionId) => {
        if(sectionId){
            resetFilters();
            if(history.location.pathname !== '/glossar'){
                history.push('/glossar');
            }
        }
        setActiveSectionId(sectionId);
    }, [history, setActiveSectionId, resetFilters]);


    // set items depending on userRole
    useEffect(() => {
        setItems(toolbarItems.filter(item => (item.to === '/favorites' ? (userRole !== '10') : true)))
    }, [userRole]);

    // update items
    useEffect(() => {
        toolbarItems.map(item => (item.active = item.id === activeId));
    }, [activeId]);

    // close control if route changed
    useEffect(() => {
        const tmpItem = toolbarItems.filter(item => (item.to === pathname.substr(0,item.to.length)))[0];
        if(tmpItem){
            if(!tmpItem.controls || !tmpItem.active){
                closeControls();
            } else if(closeOnChangeRoute && tmpItem.to !== pathname){
                closeControls();
            }
        } else {
            closeControls();
        }
    }, [pathname, closeControls, closeOnChangeRoute]);

    const classNames = [baseClassName];
    if(userRole && userRole === '10'){
        classNames.push(`${baseClassName}--guest`);
    }

    return (
        <nav className={classNames.join(' ').trim()}>

            <LayoutContainer hasPadding={false} extraSmall>
                <div className={`${baseClassName}__inner`}>
                    <Link to="/">
                        <Logo />
                    </Link>
                    <ul className={`${baseClassName}__nav`}>
                        {
                            items.map(item => (
                                <ToolbarItem
                                    key={item.id}
                                    iconSize={24} {...item}
                                    onClick={(event) => handleClickItem(item, event)}
                                />
                            ))
                        }
                    </ul>
                </div>
            </LayoutContainer>

            <div className={`${baseClassName}__controls ${baseClassName}__controls--${controlsOpen ? 'open' : 'closed'}`}>
                <div className={`${baseClassName}__controls__slider ${baseClassName}__controls__slider--${controlsOpen ? 'open' : 'closed'}`}>
                    <div className={`${baseClassName}__controls__inner`}>
                        <div className={`${baseClassName}__controls__content`}>

                            <Searchbar
                                enabled={activeId === 'search'}
                                searchQuery={searchQuery}
                                onChange={handleSearch}
                                onReturn={closeControls}
                                toggleOffcanvas={toggleOffcanvas}
                            />

                            <GlossaryNav
                                enabled={activeId === 'glossar'}
                                sections={sections}
                                activeId={activeSectionId}
                                onSelect={handleGlossaryNav}
                            />

                        </div>
                    </div>
                    <div className={`${baseClassName}__controls__close`} onClick={closeControls}>
                        <IconSvg
                            name={'cancel'}
                            size={12}
                        />
                    </div>
                </div>
            </div>

        </nav>
    );
}


Toolbar.propTypes = {
    changeRouteOnOpen: PropTypes.bool,
    closeOnChangeRoute: PropTypes.bool,
};

Toolbar.defaultProps = {
    changeRouteOnOpen: true,
    closeOnChangeRoute: true,
};