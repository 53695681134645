import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// context
import AppContext from '../../context/AppContext';

// hooks
import useNetworkConnection from '../../hooks/useNetworkConnection';

// helpers
import { login } from '../../api/auth/login';
import { fetchArticles } from '../../api/article/fetch';
import { fetchTags } from '../../api/tags/fetch';
import { getFavoriteArticles, getUserFeedback } from '../../api/user';
import { setFavoritArticles } from '../../state/setFavoritArticles';
import { submitArticleFeedback } from '../../api/article/feedback';
import { submitArticleSuggestion } from '../../api/article/suggestion';
import { getFilteredArticles } from '../../state/handleArticleFiltering';
import { shuffle } from '../../helpers/shuffle';
import { getSomeArticlesByTagId } from '../../helpers/getArticlesByTagId';

// constants
const API_BASE_URL = process.env.REACT_APP_API_URL;
const NUM_RANDOM_ARTICLES = 10;
/** token storage expiration time (24h) */
const TOKEN_EXPIRATION = 24 * 60 * 60 * 1000;

/**
 *
 * @param children
 * @returns {XML}
 * @constructor
 */
export function AppContextProvider({children}) {

    // STATE  ------------------------------------------------------------------
    const [isLoaded, setIsLoaded] = useState(false);

    const hasNetworkConnection = useNetworkConnection();
    //const [hasNetworkConnection, setHasNetworkConnection] = useState();
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [userRole, setUserRole] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [apiOptions, setApiOptions] = useState({apiBaseUrl: API_BASE_URL});

    const [selectedLanguage, setSelectedLanguage] = useState('de');

    const [availableArticles, setAvailableArticles] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [locallySelectedTags, setLocallySelectedTags] = useState([]);

    const [favoritArticles, setFavoritArticlesInternal] = useState(null);
    const [favoritTags, setFavoritTags] = useState(null);
    const [locallySelectedFavoritTags, setLocallySelectedFavoritTags] = useState([]);

    const [filteredArticles, setFilteredArticles] = useState(null);
    const [locallyFilteredArticles, setLocallyFilteredArticles] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [randomArticles, setRandomArticles] = useState([]);


    // CALLBACKS  -------------------------------------------------------------

    const resetFilters = useCallback(() => {
        setFilteredArticles(null);
        setLocallyFilteredArticles([]);
        setSelectedTags([]);
        setLocallySelectedTags([]);
        setSearchQuery('');
    }, []);

    const getArticlesById = useCallback((articleIDs) => {
        if (!availableArticles || !articleIDs) {
            return null;
        }
        return availableArticles.filter(availableArticle => articleIDs.some(id => availableArticle.id === id));
    }, [availableArticles]);

    const showRandomArticles = useCallback(() => {
        if (!availableArticles || !availableArticles.length) return null;
        resetFilters();
        const articlesToShuffle = (favoritTags !== null && favoritTags.length > 0) ?
            getSomeArticlesByTagId(availableArticles, favoritTags) :
            availableArticles.map(article => article.id);
        const shuffeledArticles = shuffle(articlesToShuffle);
        const result = shuffeledArticles.length > NUM_RANDOM_ARTICLES ?
            shuffeledArticles.slice(0, NUM_RANDOM_ARTICLES) :
            shuffeledArticles;
        setRandomArticles(result);
    }, [availableArticles, favoritTags, resetFilters]);

    const fetchFromApi = useCallback(() => {
        console.log('Loading data ...');
        fetchArticles(apiOptions, ({availableArticles}) => {
            setAvailableArticles(availableArticles);
        });
        fetchTags(apiOptions, ({availableTags}) => {
            setAvailableTags(availableTags);
        });
        if (apiOptions.userRole !== "10") {
            getFavoriteArticles(apiOptions, ({favoritArticles}) => {
                setFavoritArticlesInternal(favoritArticles);
            });
        }
    }, [apiOptions]);


    const toggleOffcanvas = useCallback(() => {
        setIsOffcanvasOpen(!isOffcanvasOpen);
    }, [isOffcanvasOpen]);

    const handleLogin = useCallback((data, callback) => {
        return login(data, apiOptions, callback);
    }, [apiOptions]);

    const handleSetUserRole = useCallback(role => {
        setUserRole(role !== undefined ? `${role}` : null)
    }, []);

    const handleSetFavoritArticles = useCallback((articleId) => {
        const state = {
            ...apiOptions,
            favoritArticles: favoritArticles,
        };
        return setFavoritArticles(articleId, state, (newState) => {
            setFavoritArticlesInternal(newState.favoritArticles);
        });
    }, [apiOptions, favoritArticles]);

    const handleSetFavoritTags = useCallback((tags, callback) => {
        setFavoritTags(tags);
        callback && callback();
    }, []);

    const handleSubmitArticleFeedback = useCallback((articleId, data) => {
        return submitArticleFeedback(articleId, data, apiOptions);
    }, [apiOptions]);


    const handleSubmitArticleSuggestion = useCallback((articleId, data) => {
        return submitArticleSuggestion(articleId, data, apiOptions)
            .then(response => {
                if(response && !articleId){
                    // this article has been created by searchQuery
                    setSearchQuery('');
                }
                return response;
            });
    }, [apiOptions]);

    // set/remove stored token and set isLoggedIn
    useEffect(() => {
        if(accessToken){
            const storedToken = window.sessionStorage.getItem('accessToken');
            if (storedToken !== accessToken) {
                // store token in sessionStorage
                window.sessionStorage.setItem('accessToken', accessToken);
                // store token expiration in sessionStorage
                window.sessionStorage.setItem('accessTokenExpiresAt', new Date().getTime() + TOKEN_EXPIRATION);
            }
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [accessToken]);

    // store userRole
    useEffect(() => {
        if(userRole){
            window.sessionStorage.setItem('userRole', userRole);
        } else {
            window.sessionStorage.removeItem('userRole');
        }
    }, [userRole]);

    // store apiOptions
    useEffect(() => {
        setApiOptions({
            apiBaseUrl: API_BASE_URL,
            accessToken: accessToken,
            userRole: userRole,
        });
    }, [userRole, accessToken]);

    // load data when user has logged in
    useEffect(() => {
        if(!isLoaded && isLoggedIn){
            setIsLoaded(true);
            fetchFromApi();
        }
    }, [isLoaded, isLoggedIn, fetchFromApi]);

    // show random articles when data is loaded
    useEffect(() => {
        if(availableArticles.length && availableTags.length){
            showRandomArticles();
        }
    }, [availableArticles, availableTags, showRandomArticles]);

    // random articles changed
    useEffect(() => {
        if(randomArticles && randomArticles.length){
            window.scrollTo(0, 0);
        }
    }, [randomArticles]);

    // apply filters
    useEffect(() => {
        if (searchQuery.length > 0 || selectedTags.length > 0) {
            const filteredResults = getFilteredArticles(availableArticles, selectedTags, searchQuery);
            setFilteredArticles(filteredResults);
            setLocallyFilteredArticles(filteredResults);
        } else {
            setFilteredArticles(null);
        }
    }, [availableArticles, searchQuery, selectedTags]);

    // apply local filters
    useEffect(() => {
        if(searchQuery.length > 0 || locallySelectedTags.length){
            setLocallyFilteredArticles(getFilteredArticles(availableArticles, locallySelectedTags, searchQuery));
        } else {
            setLocallyFilteredArticles([]);
        }
    }, [availableArticles, searchQuery, locallySelectedTags, setLocallyFilteredArticles]);

    // logout user if logout event was dispatched in handleExpiredLogin
    useEffect(() => {
        window.addEventListener("logout", () => {
            console.log("Received logout event");
            setIsLoggedIn(false);
        });
    }, []);

    return (
        <AppContext.Provider value={{
            apiBaseUrl: API_BASE_URL,
            hasNetworkConnection: hasNetworkConnection,

            isLoggedIn: isLoggedIn,
            setLogin: setIsLoggedIn,
            userRole: userRole,
            setUserRole: handleSetUserRole,
            accessToken: accessToken,
            setAccessToken: setAccessToken,

            login: handleLogin,

            isOffcanvasOpen: isOffcanvasOpen,
            toggleOffcanvas: toggleOffcanvas,

            selectedLanguage: selectedLanguage,
            setSelectedLanguage: setSelectedLanguage,

            availableArticles: availableArticles,
            setAvailableArticles: setAvailableArticles,
            availableTags: availableTags,
            setAvailableTags: setAvailableTags,

            favoritArticles: favoritArticles,
            setFavoritArticles: handleSetFavoritArticles,
            favoritTags: favoritTags,
            setFavoritTags: handleSetFavoritTags,

            searchQuery: searchQuery,
            setSearchQuery: setSearchQuery,
            resetFilters: resetFilters,

            randomArticles: randomArticles,
            setRandomArticles: showRandomArticles,
            filteredArticles: filteredArticles,
            setFilteredArticles: setFilteredArticles,
            locallyFilteredArticles: locallyFilteredArticles,
            setLocallyFilteredArticles: setLocallyFilteredArticles,

            selectedTags: selectedTags,
            setSelectedTags: setSelectedTags,
            locallySelectedTags: locallySelectedTags,
            setLocallySelectedTags: setLocallySelectedTags,
            locallySelectedFavoritTags: locallySelectedFavoritTags,
            setLocallySelectedFavoritTags: setLocallySelectedFavoritTags,

            getArticlesById: getArticlesById,
            submitArticleFeedback: handleSubmitArticleFeedback,
            submitArticleSuggestion: handleSubmitArticleSuggestion,
            getUserFeedback: getUserFeedback,
        }}>
            {children}
        </AppContext.Provider>
    )
}

AppContextProvider.propTypes = {
    children: PropTypes.any,
};

export function withAppContext(Component){
    return (props) => {
        return(
            <AppContextProvider>
                <Component {...props}/>
            </AppContextProvider>
        );
    }
}
