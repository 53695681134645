import { createContext } from 'react';

const initialValue = {
    apiBaseUrl: '',
    hasNetworkConnection: null,

    isLoggedIn: null,
    setLogin: () => {},
    userRole: null,
    setUserRole: () => {},
    accessToken: '',
    setAccessToken: () => {},
    login: () => {},

    isOffcanvasOpen: false,
    toggleOffcanvas: () => {},

    selectedLanguage: '',
    setSelectedLanguage: () => {},

    availableArticles: [],
    setAvailableArticles: () => {},
    availableTags: [],
    setAvailableTags: () => {},

    favoritArticles: null,
    setFavoritArticles: () => {},
    favoritTags: null,
    setFavoritTags: () => {},

    searchQuery: '',
    setSearchQuery: () => {},
    resetFilters: () => {},

    randomArticles: [],
    setRandomArticles: () => {},
    filteredArticles: null,
    setFilteredArticles: () => {},
    locallyFilteredArticles: [],
    setLocallyFilteredArticles: () => {},

    selectedTags: [],
    setSelectedTags: () => {},
    locallySelectedTags: [],
    setLocallySelectedTags: () => {},
    locallySelectedFavoritTags: [],
    setLocallySelectedFavoritTags: () => {},

    getArticlesById: () => {},
    submitArticleSuggestion: () => {},
    submitArticleFeedback: () => {},
    getUserFeedback: () => {},
};

const AppContext = createContext(initialValue);
export default AppContext;