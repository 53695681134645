import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';
import LoadingIndicator from '../LoadingIndicator';
import './_TagList.scss';

export default class TagList extends PureComponent {
    render() {
        const {
            isLinked,
            isSelectable,
            isRemovable,
            hasPadding,
            tags,
            selectedTags,
            toggle,
            remove,
            click,
            maxHeight,
            flatList,
            horizontal,
            outline,
            style
        } = this.props;

        if (tags === null) return <LoadingIndicator />;
        if (tags && tags.length <= 0) return null;

        const listStyle = {
            maxHeight
        }
        return (
            <div className={`odt-TagList-wrapper ${hasPadding ? 'has-padding' : ''}`} style={style}>
                <ul className={`odt-TagList ${flatList ? 'odt-TagList--flatList' : ''} ${horizontal ? 'odt-TagList--horizontal' : ''}`} style={listStyle}>
                    {
                        tags && tags.map(tag => { return (
                            <Tag
                                id={tag.id}
                                key={tag.id}
                                title={tag.title}
                                isLinked={isLinked}
                                isSelectable={isSelectable}
                                isRemovable={isRemovable}
                                isSelected={selectedTags && selectedTags.filter(selectedTag => selectedTag.id === tag.id).length > 0 ? true : false}
                                outline={outline}
                                toggle={toggle}
                                remove={remove}
                                click={click}
                            />
                        )})
                    }
                </ul>
            </div>
        );
    }
}

TagList.propTypes = {
    hasPadding: PropTypes.bool,
    isSelectable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isLinked: PropTypes.bool,
    tags: PropTypes.array.isRequired,
    selectedTags: PropTypes.array,
    toggle: PropTypes.func,
    remove: PropTypes.func
}

TagList.defaultProps = {
    hasPadding: true,
    isLinked: false,
    isRemovable: false,
    isSelectable: true,
}