import { get } from "../request";

export async function fetchArticles(state, setState){
    const route = `/articles`;
    return get(state, route).then(response => {
        const { articles } = response;
        if (!articles){
            return;
        }
        setState({ availableArticles: articles });
        console.log('Fetched articles');
    });
}