import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedbackHeader from './FeedbackHeader';
import FeedbackFooter from './FeedbackFooter';
import FeedbackSteps from './FeedbackSteps';
import toast from '../Toast';
import IconSvg from '../IconSvg';
import './_Feedback.scss';

class Feedback extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: null,
            isFinished: false,
            hasFailed: false,
            wasHelpful: null,
            step: null,
            selectedOptions: [],
            note: ''
        }
        this._clickYes = this._clickYes.bind(this);
        this._clickNo = this._clickNo.bind(this);
        this._nextStep = this._nextStep.bind(this);
        this._prevStep = this._prevStep.bind(this);
        this._cancel = this._cancel.bind(this);
        this._submit = this._submit.bind(this);
        this.setSelectedOption = this.setSelectedOption.bind(this);
        this.setNote = this.setNote.bind(this);
    }

    _clickYes() {
        return new Promise(resolve => {
            this.setState({
                wasHelpful: true,
                selectedOptions: [],
                note: ''
            }, () => {
                this.refFeedback.scrollIntoView(false);
                resolve(this._submit());
            });
        });
    }

    _clickNo(event) {
        event.preventDefault();

        this.setState({
            wasHelpful: false,
            step: 0
        }, () => {
            this.refFeedback.scrollIntoView(false);
        });
    }

    _nextStep() {
        this.setState(prevState => ({
            step: prevState.step += 1
        }), () => {
            this.refFeedback.scrollIntoView(false);
        });
    }

    _prevStep() {
        this.setState(prevState => ({
            step: prevState.step !== 0 ? prevState.step -= 1 : 0
        }), () => {
            this.refFeedback.scrollIntoView();
        });
    }

    _cancel() {
        this.setState({
            wasHelpful: null,
            step: null,
            selectedOptions: [],
            note: ''
        }, () => {
            this.refFeedback.scrollIntoView(false);
        });
    }

    _submit() {
        const { id, submitArticleFeedback } = this.props;
        const { wasHelpful, selectedOptions, note } = this.state;
        const data = {
            article_id: id,
            helpful: +wasHelpful,
            reason: selectedOptions.length > 0 ? selectedOptions.join(',') : "",
            comment: note
        };
        const reloadButton = window.navigator.onLine ? <button onClick={() => { window.location.reload(); window.sessionStorage.clear(); }}><IconSvg name="reload" caption="Neu laden" /></button> : null;
        const errorMessage = (
            <div className="toast__columns">
                {
                    navigator.onLine ?
                        'Etwas ist schief gelaufen. Bitte probieren Sie es erneut.'
                        : 'Bitte überprüfen Sie Ihre Internetverbindung.'
                }
                {reloadButton}
            </div>
        );
        const successMessage = <div><IconSvg name="checkCircle" />Vielen Dank für Ihr Feedback.</div>;

        this.setState({ isLoading: true });

        console.log(`Sending Feedback for ${id}: ${wasHelpful ? 'helpfull': 'not helpful'}, ${selectedOptions}, ${note}...`);

        submitArticleFeedback(id, data)
            .then(response => {
                if (!response) {
                    toast.error(errorMessage);
                    this.setState({ isLoading: false });
                    return;
                }
                this.setState({ isLoading: false, isFinished: true });
                toast.success(successMessage);
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    hasFailed: true
                }, () => {
                    setTimeout(() => {
                        this.setState({ hasFailed: false })
                    }, 1000);
                });
                toast.error(errorMessage);
            })
    }

    setSelectedOption(value) {
        this.setState({
            selectedOptions: [
                ...value
            ]
        });
    }

    setNote(value) {
        this.setState({ note: value })
    }

    render() {
        const { userRole, linkUpdate, alreadyFeedbacked } = this.props;
        const {
            wasHelpful,
            step,
            selectedOptions,
            note,
            isFinished,
            isLoading
        } = this.state;
        const surveyEnd = step === 0;

        if (isFinished || (userRole && userRole === "10")) return null;

        return (
            <div className={`odt-Feedback ${alreadyFeedbacked ? 'is-disabled' : ''}`} ref={el => this.refFeedback = el}>
                <FeedbackHeader
                    wasHelpful={wasHelpful}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    linkUpdate={linkUpdate}
                    step={step}
                    clickYes={this._clickYes}
                    clickNo={this._clickNo}
                />

                <FeedbackSteps
                    wasHelpful={wasHelpful}
                    step={step}
                    isFinished={isFinished}
                    selectedOptions={selectedOptions}
                    note={note}
                    setSelectedOption={this.setSelectedOption}
                    setNote={this.setNote}
                />

                <FeedbackFooter
                    isFinished={isFinished}
                    isLoading={isLoading}
                    wasHelpful={wasHelpful}
                    step={step}
                    selectedOptions={selectedOptions}
                    note={note}
                    surveyEnd={surveyEnd}
                    clickNext={this._nextStep}
                    clickPrev={this._prevStep}
                    cancel={this._cancel}
                    clickSubmit={this._submit}
                />
            </div>
        );
    }
}

Feedback.propTypes = {
    id: PropTypes.number.isRequired
}

export default Feedback;
