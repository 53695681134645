import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../context/AppContext';

import './_Searchbar.scss';
import IconSvg from '../IconSvg';
import LayoutContainer from '../LayoutContainer';
import SearchField from '../SearchField';

const baseClassName = 'odt-Searchbar';

export default function Searchbar({enabled, onChange, onReturn}){

    const {
        searchQuery,
        toggleOffcanvas,
        } = useContext(AppContext);

    const containerRef = useRef();
    const [searchStr, setSearchStr] = useState(searchQuery);

    const handleKeyPress = useCallback((event) => {
        if(event.keyCode === 13){
            document.activeElement.blur();
            onReturn();
        }
    }, [onReturn]);

    const handleFocus = useCallback(() => {
        document.addEventListener('keydown', handleKeyPress, true);
    }, [handleKeyPress]);

    const handleBlur = useCallback(() => {
        document.removeEventListener('keydown', handleKeyPress, true);
    }, [handleKeyPress]);

    // if enabled, focus search input after delay
    useEffect(() => {
        let timeout;
        if(enabled){
            const input = containerRef.current.querySelector('input[type=search]');
            if(input){
                timeout = setTimeout(() => {
                    input.focus();
                }, 400);
            }
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [enabled]);

    // update state
    useEffect(() => {
        setSearchStr(searchQuery);
    }, [searchQuery]);

    // call change handler
    useEffect(() => {
        let timeout;
        if(onChange){
            timeout = setTimeout(() => {
                onChange(searchStr);
            }, searchStr ? 50 : 0);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [searchStr, onChange]);


    const classNames = [baseClassName];
    if(!enabled){
        classNames.push(`${baseClassName}--disabled`);
    }
    return (
        <div className={classNames.join(' ')} ref={containerRef}>
            <LayoutContainer extraSmall>
                <div className={`${baseClassName}__inner`}>
                    <div className={`${baseClassName}__field`}>
                        <SearchField
                            light
                            hasIcon={false}
                            placeholder="Im Wörterbuch suchen..."
                            value={searchStr}
                            onChange={setSearchStr}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </div>
                    <button
                        className={`${baseClassName}__filter`}
                        onClick={toggleOffcanvas}
                    >
                        <IconSvg name="filter" size={20} caption="Filter" />
                    </button>
                </div>
            </LayoutContainer>
        </div>
    );
}

Searchbar.propTypes = {
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
    onReturn: PropTypes.func,
};

Searchbar.defaultProps = {
    onChange: () => {},
    onReturn: () => {},
};