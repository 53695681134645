import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import animate, { EasingFunctions } from '../../helpers/animate';

import './_GlossaryNav.scss';

const baseClassName = 'odt-GlossaryNav';

export default function GlossaryNav(props){

    const { enabled, sections, activeId, onSelect } = props;

    const containerRef = useRef();
    const sliderRef = useRef();

    const scrollToSection = useCallback((sectionId) => {
        if(!sections){
            return null;
        }
        const scrollContainer = containerRef.current;
        const slider = sliderRef.current;
        const index = sections.map(section => section.id).indexOf(sectionId);
        const item = slider.childNodes[index];
        if (!item) {
            return null;
        }

        const width = window.innerWidth;
        const maxScrollX = scrollContainer.scrollWidth - scrollContainer.clientWidth;
        const oldScrollX = scrollContainer.scrollLeft;
        const itemBounds = item.getBoundingClientRect();
        const targetX = 0.5 * (width - itemBounds.width);
        const scrollX = Math.max(0, Math.min(maxScrollX, oldScrollX + Math.round(itemBounds.left - targetX)));

        if(scrollX === oldScrollX){
            return null;
        }

        return animate(scrollContainer, 'scrollLeft', oldScrollX, scrollX, 300, '', true, EasingFunctions.easeInOutQuad);

    }, [sections]);


    useEffect(() => {
        let animation;
        let timeout;

        if(activeId){
            timeout = setTimeout(() => {
                animation = scrollToSection(activeId);
            }, 100);
        }

        return () => {
            if(timeout){
                clearTimeout(timeout);
            }
            if(animation){
                animation.cancel();
            }
        }

    }, [activeId, scrollToSection]);


    const handleClickItem = useCallback((id) => {
        onSelect(id);
    }, [onSelect]);

    const classNames = [baseClassName];
    if(!enabled){
        classNames.push(`${baseClassName}--disabled`);
    }
    return (
        <div className={classNames.join(' ')}>
            <div className={`${baseClassName}__content`} ref={containerRef}>
                <div className={`${baseClassName}__slider`} ref={sliderRef}>
                    {
                        sections.map(section => {
                            let classNames = [`${baseClassName}__item`];
                            if( section.id === activeId ){
                                classNames.push(`${baseClassName}__item--active`);
                            }
                            return (
                                <span key={section.id} className={classNames.join(' ').trim()}>
                                    <NavLink to={`/glossar`} onClick={() => handleClickItem(section.id)}
                                             data-id={section.id}>
                                        {section.title}
                                    </NavLink>
                                </span>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

GlossaryNav.propTypes = {
    enabled: PropTypes.bool,
    sections: PropTypes.array.isRequired,
    activeId: PropTypes.string,
    onSelect: PropTypes.func,
};
