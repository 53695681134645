import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import BackgroundContext from '../../context/BackgroundContext';
import { shuffle } from '../../helpers/shuffle';

import bg1Desktop from '../../../assets/images/dm-woerterbuch-background-01-desktop.svg';
import bg1Mobile from '../../../assets/images/dm-woerterbuch-background-01-mobile.svg';
import bg2Desktop from '../../../assets/images/dm-woerterbuch-background-02-desktop.svg';
import bg2Mobile from '../../../assets/images/dm-woerterbuch-background-02-mobile.svg';
import bg3Desktop from '../../../assets/images/dm-woerterbuch-background-03-desktop.svg';
import bg3Mobile from '../../../assets/images/dm-woerterbuch-background-03-mobile.svg';
import bg4Desktop from '../../../assets/images/dm-woerterbuch-background-04-desktop.svg';
import bg4Mobile from '../../../assets/images/dm-woerterbuch-background-04-mobile.svg';
import bg5Desktop from '../../../assets/images/dm-woerterbuch-background-05-desktop.svg';
import bg5Mobile from '../../../assets/images/dm-woerterbuch-background-05-mobile.svg';

const backgrounds = [
    {
        mobile: bg1Mobile,
        desktop: bg1Desktop,
    },
    {
        mobile: bg2Mobile,
        desktop: bg2Desktop,
    },
    {
        mobile: bg3Mobile,
        desktop: bg3Desktop,
    },
    {
        mobile: bg4Mobile,
        desktop: bg4Desktop,
    },
    {
        mobile: bg5Mobile,
        desktop: bg5Desktop,
    },
];

const initialBackground = shuffle(backgrounds)[0];

export default function BackgroundContextProvider({children}){

    const [currentBackground, setCurrentBackground] = useState(initialBackground);

    const setRandomBackground = useCallback(() => {
        const randomBg = shuffle(backgrounds.filter(bg => bg !== currentBackground))[0];
        setCurrentBackground(randomBg);
    }, [currentBackground]);

    return (
        <BackgroundContext.Provider value={{
            currentBackground: currentBackground,
            setRandomBackground: setRandomBackground,
        }}
        >
            { children }
        </BackgroundContext.Provider>
    )
}

BackgroundContextProvider.propTypes = {
    children: PropTypes.any,
};

export function withBackground(Component){
    return (props) => {
        return(
            <BackgroundContextProvider>
                <Component {...props}/>
            </BackgroundContextProvider>
        );
    }
}