import React, { Component } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import Box from '../Box';
//import RichtextEditor from '../RichtextEditor';
import { FormField, FormButtons, FormHeader } from '../Form';
import LayoutContainer from '../LayoutContainer';
import TagsField from './tags';
import Headline from '../Headline';

class ArticleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChanged: false,
            data: this._getArticleData(this.props.article),
            isFocused: false,
        };
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.article !== prevProps.article) {
            this.setState({
                isChanged: false,
                data: this._getArticleData(this.props.article)
            });
        }

        if (this.state.isFocused !== prevState.isFocused) {
            setTimeout(() => {
                if (this.state.isFocused === false) {
                    window.scrollTo(0, 0);
                }
            })
        }

    }

    _getArticleData = (article) => {
        if(!article){
            return {};
        }
        //console.log('_getArticleData', article);
        return {
            title: article.title,
            content: article.content,
            plain_content: striptags(article.content),
            tags: article.tags,
        }
    };

    _onFieldChange = (event) => {
        const input = event.target;
        const attrib = input.getAttribute('name');
        const val = input.value;

        const { onChange } = this.props;
        let { data } = this.state;

        if( data[attrib] !== val ){
            data[attrib] = val;
            this.setState({
                data: data,
                isChanged: true
            });
            onChange && onChange(data);
        }
    };

    _onFieldBlur = () => {
        this.setState({
            isFocused: false,
        });
    };

    _onFieldFocus = () => {
        this.setState({
            isFocused: true,
        });
    }

    _setContent = (content) => {
        const { onChange } = this.props;
        let { data } = this.state;

        if(data.content !== content){
            data.content = content;
            this.setState({
                data: data,
                isChanged: true
            });
            onChange && onChange(data);
        }
    };

    _setTags = (clickedTag) => {
        console.log(clickedTag);
        const { onChange } = this.props;
        const { data } = this.state;


        const isAlreadySelected = data.tags.filter(localTag => localTag === clickedTag).length > 0;
        let updatedTagSelection = [];

        if (isAlreadySelected) {
            updatedTagSelection = [
                ...data.tags.filter(localTag => localTag !== clickedTag)
            ];
        } else {
            updatedTagSelection = [
                ...data.tags,
                clickedTag
            ];
        }

        data.tags = updatedTagSelection;
        this.setState({
            data: data,
            isChanged: true
        });

        onChange && onChange(data);
    };

    _onSubmit = (event) => {
        event.preventDefault();
        const {onSubmit} = this.props;
        onSubmit(this.state.data);
    };


    render(){
        const { article, formTitle, canSubmit, tagsEnabled } = this.props;
        const { data } = this.state;
        const isNew = !article.id;

        return (
            <div className="ArticleForm" id={`ArticleForm-${article.id}`}>
                <div className="ArticleForm-header">
                    <LayoutContainer extraSmall>
                        <FormButtons {...this.props} disabled={!canSubmit} labelSubmit="Vorschlag senden" onSubmit={this._onSubmit}/>
                        {formTitle && <FormHeader><Headline size="subtitle3">{formTitle}</Headline></FormHeader>}
                    </LayoutContainer>
                </div>
                <Box className="ArticleForm-content">
                    <LayoutContainer extraSmall>
                        <FormField
                            name="title"
                            placeholder="Begriff eingeben"
                            value={data.title}
                            onChange={this._onFieldChange}
                            onBlur={this._onFieldBlur}
                            onFocus={this._onFieldFocus}
                        />

                        <FormField name="content" type="component">
                            {
                                /*
                                 * Replaced by native text field for now.
                                 * There were bugs on mobile devices especially Android.
                                 *
                                <RichtextEditor
                                    placeholder="Definition eingeben"
                                    controlsEnabled={false}
                                    source={data.content}
                                    onChange={this._setContent}
                                    onBlur={this._onFieldBlur}
                                    onFocus={this._onFieldFocus}
                                />
                                */
                            }
                            <FormField
                                name="content"
                                placeholder="Definition eingeben"
                                defaultValue={data.plain_content && data.plain_content}
                                rows={8}
                                onChange={this._onFieldChange}
                                onBlur={this._onFieldBlur}
                                onFocus={this._onFieldFocus}
                            />
                        </FormField>

                        { tagsEnabled &&
                            <FormField name="tags" placeholder="Kategorien (optional)" type="component">
                                <TagsField
                                    tags={data.tags}
                                    placeholder="Kategorie"
                                    availableTags={this.props.availableTags}
                                    onChange={this._setTags}
                                />
                            </FormField>
                        }
                        { !isNew &&
                            <FormField
                                name="comment"
                                rows={4}
                                placeholder="Kommentar oder Empfehlung (Optional)"
                                onChange={this._onFieldChange}
                                onBlur={this._onFieldBlur}
                                onFocus={this._onFieldFocus}
                            />
                        }
                    </LayoutContainer>
                </Box>
            </div>
        );
    }
}


ArticleForm.propTypes = {
    formTitle: PropTypes.string,
    article: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        tags: PropTypes.array
    }),
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    canSubmit: PropTypes.bool,
    tagsEnabled: PropTypes.bool,
};

ArticleForm.defaultProps = {
    formTitle: 'Begriff vorschlagen',
    canSubmit: true,
    tagsEnabled: false,
};

export default ArticleForm;