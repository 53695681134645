import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../../Button';
import { filterArticlesByQuery } from '../../../helpers/filterArticlesByQuery';
import './_SearchNotFound.scss';
import Card from '../../Card';
import Headline from '../../Headline';
import Paragraph from '../../Paragraph';
import LayoutContainer from '../../LayoutContainer';


class SearchNotFound extends PureComponent {
    render() {
        const { location, availableArticles, renderHeader, searchQuery } = this.props;
        const isArticleExisting = filterArticlesByQuery(availableArticles, searchQuery).length > 0;
        // retrieve base route from router location
        const baseRoute = '/' + location.pathname.replace(/^\//, '').split('/')[0];

        return (
            <div className="odt-SearchNotFound">
                <LayoutContainer medium hasPadding={false}>
                    {
                        isArticleExisting
                            ?
                            <React.Fragment>
                                {renderHeader}
                                <Card isClickable={false}>
                                    <Headline as="h1" size="h6">Sucheinstellungen ändern</Headline>
                                    <Paragraph size="body2">Mit den von Ihnen gewählten Sucheinstellungen konnten leider keine Begriffe gefunden werden.</Paragraph>
                                </Card>
                            </React.Fragment>
                            :
                            <Card isClickable={false}>
                                <Headline as="h1" size="h6">
                                    Der Begriff „<span className="odt-SearchNotFound__query">{searchQuery}</span>“ existiert im dm-Wörterbuch nicht.
                                </Headline>
                                <Button type="link" to={`${baseRoute}/article/create`} secondary>
                                    Begriff vorschlagen
                                </Button>
                            </Card>
                    }
                </LayoutContainer>
            </div>
        );
    }
}

export default withRouter(SearchNotFound);