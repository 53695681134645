import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import TagList from '../TagList';
import { getTagsById } from '../../helpers/getTagsById';
import Headline from '../Headline';
import TagListSearchableLight from '../TagListSearchableLight';
import './_TagListSearchable.scss';

export default class TagListSearchable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeSearch: false
        }
        this.handleSearchInput = this.handleSearchInput.bind(this);
        this.toggleTags = this.toggleTags.bind(this);
        this._submit = this._submit.bind(this);
    }

    handleSearchInput(query) {
        this.setState({ activeSearch: query.length > 0 })
    }

    toggleTags(clickedTag) {
        const { locallySelectedTags, handleLocalChanges, setLocallySelectedTags } = this.props;
        const isAlreadySelected = locallySelectedTags.filter(localTag => localTag === clickedTag).length > 0;
        let updatedTagSelection = [];

        if (isAlreadySelected) {
            updatedTagSelection = [
                ...locallySelectedTags.filter(localTag => localTag !== clickedTag)
            ];
        } else {
            updatedTagSelection = [
                ...locallySelectedTags,
                clickedTag
            ];
        }

        setLocallySelectedTags(updatedTagSelection);

        if(handleLocalChanges) {
            handleLocalChanges(updatedTagSelection);
        }
    }

    _submit() {
        const { submit, locallySelectedTags } = this.props;
        if (submit){
            submit(locallySelectedTags);
            return;
        }
    }

    render() {
        const {
            tags,
            locallySelectedTags,
            buttonText,
            buttonDisabled,
            searchTagsEnabled
        } = this.props;
        const { activeSearch } = this.state;
        const selectedTags = getTagsById(tags, locallySelectedTags);

        return (
            <div className="odt-TagListSearchable">

                { searchTagsEnabled &&
                    <div className="odt-TagListSearchable__header" style={{ minHeight: 100 }}>
                        <TagListSearchableLight
                            flatList
                            inputPlaceholder={'Nach Kategorie filtern'}
                            tags={tags}
                            selectedTags={selectedTags}
                            clickTag={this.toggleTags}
                            setLocallySelectedTags={this.toggleTags}
                            onChange={this.handleSearchInput}
                        />
                    </div>
                }

                {
                    !activeSearch
                        ?
                        <div className="odt-TagListSearchable__body">
                            <Headline size="subtitle3">Alle Kategorien auf einen Blick</Headline>
                            <TagList
                                outline
                                maxHeight={350}
                                tags={tags}
                                selectedTags={selectedTags}
                                toggle={this.toggleTags}
                            />
                        </div>
                        :
                        null
                }

                <div className="odt-TagListSearchable__footer">
                    <Button
                        secondary
                        disabled={buttonDisabled}
                        value={buttonText}
                        onClick={this._submit}
                    />
                </div>

            </div>
        );
    }
}

TagListSearchable.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    })).isRequired,
    selectedTags: PropTypes.arrayOf(PropTypes.number),
    buttonText: PropTypes.any,
    buttonDisabled: PropTypes.bool,
    searchTagsEnabled: PropTypes.bool,
    setLocallySelectedTags: PropTypes.func,
    handleLocalChanges: PropTypes.func,
    submit: PropTypes.func
}

TagListSearchable.defaultProps = {
    buttonText: "Submit",
    buttonDisabled: false,
    searchTagsEnabled: false,
    selectedTags: [],
    locallySelectedTags: []
}