import { toast } from 'react-toastify';
import { css } from 'glamor';
import './_Toast.scss';

const DEFAULT_STYLES = {
    color: '#fff!important',
    fontSize: '14px!important',
    background: '#009DA2!important',
    padding: '16px!important',
};

export default {
    // Keep the signature of the original toast object
    // Doing so you can pass additionnal options
    success(msg, options = {}) {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            closeButton: false,
            className: css({
                ...DEFAULT_STYLES,
                background: '#009DA2!important',
            })
        });
    },
    info(msg, options = {}) {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            closeButton: false,
            className: css({
                ...DEFAULT_STYLES,
                background: '#0000CD!important',
            })
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            // Merge additionals options
            ...options,
            closeButton: false,
            className: css({
                ...DEFAULT_STYLES,
                background: '#AE356A!important',
            })
        });
    },
}
