import React, { Component } from 'react';
import FavoritesListItem from './item';

export default class FavoritesList extends Component {
    render() {
        const { articles, loading } = this.props;

        return (
            <div className="odt-FavoritesList" style={{ marginTop: 24, paddingBottom: 64 }}>
                {
                    articles.map(article => {
                        return (
                            <FavoritesListItem
                                isLoading={loading.indexOf(article.id) !== -1}
                                baseRoute={this.props.baseRoute}
                                key={article.id}
                                id={article.id}
                                title={article.title}
                                remove={this.props.remove}
                            />
                        )
                    })
                }
            </div>
        );
    }
}