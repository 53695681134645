import React, { Component } from 'react';
import LayoutContainer from '../LayoutContainer';
import Headline from '../Headline';
import Button from '../Button';

export default class NotFound extends Component {
    render() {
        return (
            <div
                className="odt-NotFound"
                style={{
                    position: 'relative',
                    //top: 60,
                    //minHeight: 'calc(100vh - 60px)',
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LayoutContainer extraSmall>
                    <Headline as="h1" size="h5">Leider nichts gefunden...</Headline>
                    <Button
                        type="link"
                        text
                        noPadding
                        icon="arrowLeft"
                        iconSize={16}
                        to="/"
                        value="Zurück zur Startseite"
                    />
                </LayoutContainer>
            </div>
        );
    }
}