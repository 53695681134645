import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GlossaryListSection from './section';
import LayoutContainer from '../LayoutContainer';
import './_GlossaryList.scss';

const baseClassName = 'GlossaryList';
const scrollTopMargin = 15;

export default function GlossaryList(props){

    const {sections, locked, focusSectionId, onFocusSection, hideScrollbar} = props;

    const [activeSectionIds, setActiveSectionIds] = useState([]);
    const [activeSection, setActiveSection] = useState(null);
    const [hasScrolled, setHasScrolled] = useState(false);

    const containerRef = useRef();
    const contentRef = useRef();

    // restore scroll position
    useEffect(() => {
        if(GlossaryList.storedScrollPosition){
            containerRef.current.scrollTop = GlossaryList.storedScrollPosition;
        }
    }, []);

    // update activeSectionIds
    useEffect(() => {
        const container = containerRef.current;

        let didScroll = false;
        let requestID;

        const updateList = () => {
            const containerBounds = container.getBoundingClientRect();
            const visibleSectionIds = [...contentRef.current.childNodes].filter(sectionElement => {
                const sectionBounds = sectionElement.getBoundingClientRect();
                return (sectionBounds.top <= containerBounds.bottom
                && sectionBounds.bottom > containerBounds.top + scrollTopMargin);
            }).map(sectionElement => sectionElement.getAttribute('data-id'));
            if(visibleSectionIds.toString() !== activeSectionIds.toString()){
                setActiveSectionIds(visibleSectionIds);
                setActiveSection(visibleSectionIds[0]);
            }
        };

        const validateScroll = () => {
            GlossaryList.storedScrollPosition = container.scrollTop;
            updateList();
            didScroll = false;
            setHasScrolled(false);
        };

        const invalidateScroll = () => {
            if(!didScroll){
                didScroll = true;
                setHasScrolled(true);
                requestID = window.requestAnimationFrame(validateScroll);
            }
        };

        if(activeSectionIds.length){
            container.addEventListener('scroll', invalidateScroll);
        } else {
            updateList();
        }

        return () => {
            window.cancelAnimationFrame(requestID);
            container.removeEventListener('scroll', invalidateScroll);
        }

    }, [activeSectionIds]);


    // activeSection
    useEffect(() => {
        if(activeSection && onFocusSection){
            onFocusSection(activeSection);
        }
    }, [activeSection, onFocusSection]);


    // scroll to section
    useEffect(() => {

        if(activeSection && focusSectionId && !hasScrolled && activeSection !== focusSectionId){

            const scrollToSection = (sectionId) => {
                const container = containerRef.current;
                const contentNode = contentRef.current;

                const sectionNodes = [...contentNode.childNodes];
                const sectionElement = sectionNodes.filter(element => element.getAttribute('data-id') === sectionId)[0];
                if(!sectionElement) {
                    return;
                }

                const containerScrollTop = container.scrollTop;
                const containerBounds = container.getBoundingClientRect();
                const sectionBounds = sectionElement.getBoundingClientRect();
                const targetScrollTop = containerScrollTop + (sectionBounds.top - containerBounds.top) - scrollTopMargin;

                const updateScrollPos = () => {
                    container.scrollTop = targetScrollTop;
                };

                if (!locked) {
                    // Prevent scrolling momentum on touch devices
                    container.style.overflow = 'hidden';
                    window.requestAnimationFrame(() => {
                        container.style.overflow = '';
                        updateScrollPos();
                    });
                } else {
                    updateScrollPos();
                }
            };

            if(!hasScrolled){
                scrollToSection(focusSectionId);
            }
        }

    }, [activeSection, hasScrolled, focusSectionId, locked]);


    const classNames = [baseClassName];
    if(locked) {
        classNames.push(`${baseClassName}__locked`);
    }

    const containerNode = containerRef.current;

    let containerStyle = null;
    if(containerNode){
        if(hideScrollbar){
            const scrollBarSize = containerNode.offsetWidth - containerNode.clientWidth;
            if(scrollBarSize > 0){
                containerStyle = {
                    marginRight: `-${scrollBarSize}px`,
                    paddingRight: `${scrollBarSize}px`,
                };
            }
        }
    } else {
        containerStyle = {
            visibility: 'hidden',
        };
    }

    return (
        <div className={classNames.join(' ')} ref={containerRef} style={containerStyle}>
            <LayoutContainer hasPadding={false} small>
                <div className={`${baseClassName}-content`} ref={contentRef}>
                    {
                        sections.map(section =>
                            <GlossaryListSection
                                {...section}
                                key={section.id}
                                isActive={activeSectionIds.indexOf(section.id) !== -1}/>
                        )
                    }
                </div>
            </LayoutContainer>
        </div>
    );
}

GlossaryList.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            articles: PropTypes.array
        })
    ).isRequired,
    focusSectionId: PropTypes.string,
    onFocusSection: PropTypes.func,
    locked: PropTypes.bool,
    hideScrollbar: PropTypes.bool,
};

GlossaryList.defaultProps = {
    sections: [],
    locked: false,
    hideScrollbar: false,
    onFocusSection: (sectionId) => {
        console.log('onFocusSection', sectionId);
    }
};
