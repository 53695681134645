import React from 'react';
import { NavLink } from 'react-router-dom';
import IconSvg from '../../components/IconSvg';
import './_ToolbarItem.scss';

const baseClassName = 'odt-ToolbarItem';

export default function ToolbarItem({exact, to, icon, iconSize, onClick}){
    return (
        <li className={baseClassName}>
            <NavLink
                className={`${baseClassName}__link`}
                exact={exact}
                to={to}
                onClick={onClick}>
                <IconSvg
                    name={icon}
                    size={iconSize}
                />
            </NavLink>
        </li>
    );
}