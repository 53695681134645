import { post } from "../request";

export function login(data, state){
    const route = `/login`;
    return post(state, route, data).then(response => {
        if(!response || response.status === "error") {
            return;
        }
        return response;
    });
}