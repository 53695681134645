import React, { PureComponent } from 'react';

export default class FeedbackStep extends PureComponent {
    render() {
        const { step, showOnStep } = this.props;
        const classNames = ['odt-FeedbackStep'];
        if (showOnStep) classNames.push(`odt-FeedbackStep--${showOnStep}`);

        if (step !== showOnStep) return null;

        return (
            <div className={classNames.join(' ')}>
                {this.props.children}
            </div>
        );
    }
}